import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import {getDetails, logout, server} from '../api/api';
import { UserAuth } from '../types/types';

interface IUserContext {
  user: UserAuth | undefined,
  setUser: any,
  loggedIn: boolean | undefined,
  signIn: any,
  signOut: any,
  authenticateNewToken: any,
}

const STORAGE_KEY_AUTH = 'ELECCTRO_PAY:AUTH';

const UserContext = createContext<IUserContext>({
  user: undefined,
  setUser: () => {},
  loggedIn: false,
  signIn: async () => {},
  signOut: async () => {},
  authenticateNewToken: async () => {},
});

export const UserProvider = ({ children }) => {
  const tryGetStoredUser = (): UserAuth | undefined => {
    const initialItem = localStorage.getItem(STORAGE_KEY_AUTH);
    if (initialItem) {
      try {
        return JSON.parse(initialItem);
      } catch ({ message }) {
        // console.error(`useStorage#getValue: ${message}`);
        return undefined;
      }
    }
    return undefined;
  };

  const [user, setUser] = useState<UserAuth | undefined>(() => tryGetStoredUser());
  const [loggedIn, setLoggedIn] = useState<boolean>(() => {
    if (user !== undefined) {
      server.authenticate(user.token);
      return true;
    }
    return false;
  });

  const restoreAuthFromStorage = async (): Promise<boolean> => {
    const storedUser = tryGetStoredUser();
    if (storedUser !== undefined) {
      await server.authenticate(storedUser.token);
      setUser(storedUser);
      setLoggedIn(true);
      return true;
    }

    return false;
  };

  const deleteAuthStorage = () => {
    localStorage.removeItem(STORAGE_KEY_AUTH);
  };

  const saveAuthStorage = (userAuth: UserAuth) => {
    localStorage.setItem(STORAGE_KEY_AUTH, JSON.stringify(userAuth));
  };

  const signIn: (userToSignIn: UserAuth) => Promise<void> = async (userToSignIn: UserAuth) => {
    await server.authenticate(userToSignIn.token);
    setUser(userToSignIn);
    saveAuthStorage(userToSignIn);
    setLoggedIn(true);
  };

  const authenticateNewToken = async (newToken: string) => {
    await server.authenticate(newToken);
    const newUser: UserAuth = { ...user };
    newUser.token = newToken;
    setUser(newUser);
    saveAuthStorage(newUser);
    setLoggedIn(true);
  };

  const signOut: () => Promise<void> = async () => {
    await server.deauthenticate();
    deleteAuthStorage();
    await logout(user);
    setLoggedIn(false);
  };

  useEffect(() => {
    (async () => {
      if (user !== undefined) {
        try {
          await getDetails(user.token);
        } catch (e) {
          setLoggedIn(false);
          setUser(undefined);
          deleteAuthStorage();
        }
      }
    })();
  }, [loggedIn]);

  return (
    <UserContext.Provider value={{
      user, setUser, loggedIn, signIn, signOut, authenticateNewToken,
    }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
