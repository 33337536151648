import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useUser } from '../../contexts/UserContext';
import {
  FormContainer,
  StyledTextField,
} from './FormStyles';
import { emailRegex } from '../../utils/Regex';
import { login } from '../../api/api';
import NotificationToast from '../notifications/NotificationToast';
import i18n from '../../i18n/i18n';

type Error = {
  email: string | null,
  password: string | null,
}

const StyledDiv = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
`;

export default function LoginForm({
  handleError, email, password, setEmail, setPassword,
}) {
  const [notification, setNotification] = useState<any>(null);
  const [showNotification, setShowNotification] = useState(false);
  const [error, setError] = React.useState<Error>({
    email: null,
    password: null,
  });

  const { signIn } = useUser();

  useEffect(() => {
    if (email && !emailRegex.test(email)) {
      setError((prevState) => ({ ...prevState, email: i18n.t('invalid_email') }));
      handleError(true);
    } else {
      setError((prevState) => ({ ...prevState, email: null }));
      handleError(false);
    }
    if (!email?.length || !password?.length) {
      handleError(true);
    }
  }, [email, password, handleError]);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await login(email, password);
      handleError(false);
      signIn({
        user: { email, id: response.id },
        token: response.token,
      });
    } catch (ex) {
      // @ts-ignore
      if (ex.body.message === 'INVALID_DATA') {
        setNotification({
          severity: 'error',
          title: i18n.t('error'),
          message: i18n.t('login_data_invalid'),
        });
        setShowNotification(true);
      } else {
        setNotification({
          severity: 'error',
          title: i18n.t('error'),
          message: i18n.t('error_occured_try_later'),
        });
        setShowNotification(true);
      }
    }
  };

  return (
    <FormContainer>
      <NotificationToast
        open={showNotification}
        message={notification && notification.message}
        title={notification && notification.title}
        severity={notification && notification.severity}
        onClose={() => setShowNotification(false)}
      />
      <form noValidate autoComplete="off" onSubmit={onSubmit} id="login">
        <StyledDiv>
          <span>Email</span>
          <StyledTextField
            fullWidth
            onChange={(e) => setEmail(e.target.value)}
            error={!!error?.email}
            id="email"
            helperText={error.email ? error.email : ''}
          />
        </StyledDiv>
        <StyledDiv>
          <span>{i18n.t('password')}</span>
          <StyledTextField
            fullWidth
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            id="password"
          />
        </StyledDiv>
      </form>
    </FormContainer>
  );
}
