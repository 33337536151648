import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Accordion, AccordionDetails, AccordionSummary,
  Box, CircularProgress, makeStyles, Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as qs from 'qs';
import { Purchase } from '../types/types';
import { getCustomerPurchasesHistory } from '../api/api';
import { useUser } from '../contexts/UserContext';
import Header from '../components/TopHeader';
import formatDate from '../utils/DateFormatter';
import { useVendingMachine } from '../contexts/VendingMachineContext';
import BackButton from '../components/BackButton';
import BackgroundImage from '../components/BackgroundImage';
import i18n from '../i18n/i18n';

const ListButtonsContainer = styled.div`
  flex: 2;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const ListProducts = styled.div<{ height: number }>`
  flex-wrap: wrap;
  width: 100%;
  background-color: transparent;
  overflow-y: auto;
  position: relative;
  padding-bottom: 20px;
  height: ${(props) => props.height}px;
`;

const Label = styled.span`
  font-size: 1em;
  min-width: 110px;
  display: inline-block;
  font-weight: bold;
`;

const useStyles = makeStyles({

  root: {
    backgroundColor: 'rgb(255,255,255)',
    color: 'black',
    boxShadow: 'none',
  },

  rootExpanded: {
    marginBottom: '0 !important',
    marginTop: '0 !important',
  },

  summaryExpanded: {
    // paddingTop: '0px !important',
    // maxHeight: '60px !important',
    backgroundColor: 'rgb(255,255,255)',
    borderRadius: '4px',
  },

  body: {
    backgroundColor: 'transparent !important',
  },

});

const PurchasesHistoryView = ({ vm, location }) => {
  const topRef = useRef<HTMLDivElement>(null);
  const [scrollHeight, scrollWidth] = useMemo(
    () => [(window.innerHeight - (topRef?.current?.clientHeight || 0) - 250)
      , (window.innerWidth - (topRef?.current?.clientWidth || 0) - 200)], [],
  );
  const [purchases, setPurchases] = useState<Purchase[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { loggedIn } = useUser();
  const { vendingMachineInfo } = useVendingMachine();
  const history = useHistory();
  const classes = useStyles();
  const { s } = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    // TopHeader component will route to login if the user
    // is not authenticated
    if (!loggedIn) {
      return;
    }

    if (!vendingMachineInfo && !s && vm) {
      history.push('/home-no-vm');
    } else if (!vendingMachineInfo && s && vm) {
      history.push(`/load?s=${s}`);
    }

    (async () => {
      try {
        let purchasesList;
        if (vm) {
          purchasesList = await getCustomerPurchasesHistory(vendingMachineInfo?.id);
        } else {
          purchasesList = await getCustomerPurchasesHistory(false);
        }
        setPurchases(purchasesList);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError(true);
      }
    })();
  }, []);

  const content = () => {
    if (!error && loading) {
      return (
        // @ts-ignore
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          { /* @ts-ignore */}
          <CircularProgress color="inherit" />
        </Box>
      );
    }

    if (!error && purchases && purchases.length > 0) {
      return (
        <ListProducts height={scrollHeight}>
          {purchases.map((p) => (
            <Accordion
              key={p.id}
              classes={{
                root: classes.root,
                expanded: classes.rootExpanded, // class name, e.g. `classes-nesting-root-x`
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                classes={{ expanded: classes.summaryExpanded }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

                  <Typography style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    display: 'block',
                    width: `${scrollWidth / 0.65}px`,
                  }}
                  >
                    <b>{p.productName}</b>
                  </Typography>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography>{formatDate(p.date)}</Typography>
                    <Typography>{`${(p.amount / 100).toFixed(2)} €`}</Typography>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <Typography>
                    <Label>{i18n.t('product')}</Label>
                    <span style={{
                      display: 'inline-block',
                      maxWidth: '60%',
                      verticalAlign: 'top',
                    }}
                    >
                      {p.productName}
                    </span>
                  </Typography>
                  <div>
                    <Label>{i18n.t('price')}</Label>
                    <span>{`${(p.amount / 100).toFixed(2)} €`}</span>
                  </div>
                  <div>
                    <Label>{i18n.t('date')}</Label>
                    <span>{formatDate(p.date)}</span>
                  </div>
                  {
                    !vm && (
                      <>
                        <div>
                          <Label>{i18n.t('machine')}</Label>
                          <span>{p.vendingMachineName}</span>
                        </div>
                        <div>
                          <Label>{i18n.t('merchant')}</Label>
                          <span>{p.merchantName}</span>
                        </div>
                      </>
                    )
                  }
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </ListProducts>
      );
    }

    if (!error && purchases && purchases.length === 0) {
      return (
        <>
          <div style={{ flex: 1 }} />
          <h2 style={{ textAlign: 'center' }}>{i18n.t('no_purchases_registered')}</h2>
          <div style={{ flex: 1 }} />
        </>
      );
    }

    return (
      <>
        <div style={{ flex: 1 }} />
        <h2 style={{ textAlign: 'center' }}>{i18n.t('error_ocurred')}</h2>
        <h2 style={{ textAlign: 'center' }}>{i18n.t('try_again_later')}</h2>
        <div style={{ flex: 1 }} />
      </>
    );
  };

  return (
    <BackgroundImage equipmentTypeId={vendingMachineInfo?.equipmentType.id}>
      <Header
        title={i18n.t('purchases_history')}
        showVm={!history?.location?.pathname?.includes('no-vm')}
        userLogo
      />
      <ListButtonsContainer>
        {content()}
      </ListButtonsContainer>
      <div style={{ flex: 1 }} />
      <BackButton screen={vm && s ? `home?s=${s}` : '/home-no-vm'} />
    </BackgroundImage>
  );
};

export default PurchasesHistoryView;
