enum SessionStatusCodes {
  FAILED = -200,
  FAILED_UNABLE_TO_CONNECT = -201,
  FAILED_VENDING_MACHINE_NOT_CONFIGURED = -202,
  FAILED_VENDING_MACHINE_PROTOCOL_TIMEOUT = -203,
  DENIED = -100,
  DENIED_VENDING_MACHINE_HAS_CREDIT = -101,
  DENIED_VENDING_MACHINE_HAS_ACTIVE_SESSION = -102,
  DENIED_WALLET_IN_USE = -103,
  DENIED_INSUFFICIENT_FUNDS = -104,
  DENIED_DIFFERENT_CURRENCY = -105,
  DENIED_UNDER_MIN_GATEWAY_AMOUNT = -106,
  DENIED_OVER_MAX_GATEWAY_AMOUNT = -107,
  DENIED_GATEWAY_IS_INACTIVE = -108,
  REQUESTED = 0,
  ESTABLISHED = 100,
  TERMINATION_REQUESTED = 200,
  TERMINATED = 300,
  TERMINATED_SERVER_INITIATED = 310,
  TERMINATED_VMU_TIMEOUT = 321,
  TERMINATED_VMU_PROTOCOL_RESET = 322,
  TERMINATED_VMU_PROTOCOL_UNABLE_TO_INITIATE = 323,
  TERMINATED_VMU_PROTOCOL_STATE_IS_INVALID = 324,
  TERMINATED_VMU_DISCONNECTED_FROM_HUB = 325,
  TERMINATED_VMU_DISCONNECTED_FROM_SERVER = 326,
  TERMINATED_VMU_APPLICATION_ERROR = 327
}

export default SessionStatusCodes;
