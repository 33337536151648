import React, {
  createContext, useContext, useEffect, useRef, useState,
} from 'react';
import server, { openSession } from '../api/api';
import SessionStatusCodes from '../constants/SessionStatusCodes';
import { formatSessionStatus } from '../utils/MessageFormatter';
import { useVendingMachine } from './VendingMachineContext';
import { OpenSessionResponseType, PaymentMethodType } from '../types/types';
import i18n from '../i18n/i18n';

export const SessionContext = createContext<{
  sessionId: any,
  sessionState: any,
  setSessionState: any,
  selectPaymentMethod: any,
  watchSessionState: any,
  sessionError: any,
  selectedPaymentMethod: any,
  sessionBalance: any,
  setSessionBalance: any,
  watchSessionBalance: any,
  watchSessionError: any,
  reset: any,
}>({
  sessionId: undefined,
  sessionState: undefined,
  selectPaymentMethod: () => undefined,
  setSessionState: () => undefined,
  watchSessionState: () => undefined,
  sessionError: undefined,
  selectedPaymentMethod: undefined,
  sessionBalance: 0,
  setSessionBalance: () => undefined,
  watchSessionBalance: () => undefined,
  watchSessionError: () => undefined,
  reset: undefined,
});

export const SessionProvider = ({ children }) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethodType | undefined>(undefined);
  const sessionId = useRef<any>(null);
  const sessionBalance = useRef<number>(0);
  const sessionBalanceWatchers = useRef<any>([]);
  const sessionStatusCode = useRef<any>(null);
  const sessionState = useRef<any>(null);
  const sessionError = useRef<any>(null);
  const sessionStateWatchers = useRef<any>([]);
  const sessionErrorWatchers = useRef<any>([]);
  const vendingMachine = useVendingMachine();

  useEffect(() => {
    server.onSocket('session_state', handleSessionState);
    server.onSocket('transaction_state', handleTransactionState);
    server.onSocket('session_purchase', handleSessionPurchase);

    return () => {
      server.offSocket('session_state', handleSessionState);
      server.offSocket('transaction_state', handleTransactionState);
      server.offSocket('session_purchase', handleSessionPurchase);
    };
  }, []);

  const watchSessionState = (callback: any) => {
    sessionStateWatchers.current = [...sessionStateWatchers.current, callback];
  };

  const setSessionError = (error) => {
    sessionError.current = error;
    sessionErrorWatchers.current.forEach((callback) => callback(sessionError.current));
  };

  const watchSessionError = (callback) => {
    sessionErrorWatchers.current = [...sessionErrorWatchers.current, callback];
  };

  const setSessionId = (newSessionId) => {
    sessionId.current = newSessionId;
  };

  const setSessionStatusCode = (newSessionStatusCode) => {
    sessionStatusCode.current = newSessionStatusCode;
  };

  const setSessionState = (newSessionState) => {
    sessionState.current = newSessionState;
    sessionStateWatchers.current.forEach((callback) => callback(sessionState.current));
  };

  const watchSessionBalance = (callback: any) => {
    sessionBalanceWatchers.current = [...sessionBalanceWatchers.current, callback];
  };

  const setSessionBalance = (newBalance) => {
    sessionBalance.current = newBalance;
    sessionBalanceWatchers.current.forEach((callback) => callback(sessionBalance.current));
  };

  const reset = () => {
    sessionStateWatchers.current = [];
    sessionBalanceWatchers.current = [];
    sessionErrorWatchers.current = [];
    setSessionState('PAYMENT_METHOD_SELECTION');
    setSessionStatusCode(0);
    setSessionBalance(0);
    sessionId.current = undefined;
    setSelectedPaymentMethod(undefined);
    sessionStatusCode.current = undefined;
    setSessionError(undefined);
  };

  const handleSessionState = (event: { sessionId: string, state: number }) => {
    if (sessionId.current !== event.sessionId) {
      return;
    }

    switch (sessionState.current) {
      case 'CONNECTING':
        if (event.state === SessionStatusCodes.REQUESTED) {
          // NOOP
        } else if (event.state === SessionStatusCodes.ESTABLISHED) {
          setSessionState('PRODUCT_SELECTION');
        } else {
          setSessionError(formatSessionStatus(event.state));
        }
        break;

      case 'PRODUCT_SELECTION':
      case 'WAITING_FOR_PAYMENT':
      case 'PAYMENT_CONFIRMED':
        if (event.state !== SessionStatusCodes.ESTABLISHED) {
          setSessionError(formatSessionStatus(event.state));
        }
        break;

      case 'PICKUP_PRODUCT':
      default:
        break;
    }
  };

  const handleTransactionState = (event: { sessionId: string, state: any }) => {
   /* if (!event.sessionId || event.sessionId !== sessionId.current) {
      return;
    }
*/

    const isEvCharger = vendingMachine.vendingMachineInfo?.equipmentType?.label === 'EV Charger';
    const isSmartFridge = vendingMachine.vendingMachineInfo?.equipmentType?.label === 'Smart Fridge';

    if (event.state.type === 'PURCHASE' || event.state.type === 'AUTHORIZATION') {
      switch (event.state.statusCode) {
        case 'SENT':
        case 'PENDING':
          setSessionState('WAITING_FOR_PAYMENT');
          break;

        case 'SUCCEEDED':
          if (isEvCharger || isSmartFridge) {
            setSessionState('PICKUP_PRODUCT');
          } else {
            setSessionState('PAYMENT_CONFIRMED');
          }
          break;

        case 'REJECTED':
          setSessionError(i18n.t(`${event.state.type.toLowerCase()}_rejected`));
          break;

        case 'CANCELLED':
          setSessionError(i18n.t(`${event.state.type.toLowerCase()}_cancelled`));
          break;

        case 'FAILED':
          setSessionError(i18n.t(`${event.state.type.toLowerCase()}_failed`));
          break;

        case 'TIMED_OUT':
          setSessionError(i18n.t(`${event.state.type.toLowerCase()}_expired`));
          break;

        default:
          setSessionError(i18n.t(`${event.state.type.toLowerCase()}_failed`));
          break;
      }
    }

    if (event.state.type === 'MONEY_IN') {
      switch (event.state.statusCode) {
        case 'SUCCEEDED':
          setSessionBalance(sessionBalance.current + event.state.amount);
          break;

        case 'FAILED':
        default:
          break;
      }
    }
  };

  const handleSessionPurchase = (event: { sessionId: string, purchase: any }) => {
    if (sessionId.current === event.sessionId) {
      if (event.purchase.succeeded) {
        setSessionState('PICKUP_PRODUCT');
      } else {
        setSessionError(i18n.t('failed_delivering_product'));
      }
    }
  };

  const selectPaymentMethod = async (paymentMethod: PaymentMethodType, balance: number | undefined) => {
    reset();
    if (sessionState.current !== 'PAYMENT_METHOD_SELECTION') {
      throw Error('Operation not currently possible.');
    }

    const isEvCharger = vendingMachine.vendingMachineInfo?.equipmentType?.label === 'EV Charger';
    const isSmartFridge = vendingMachine.vendingMachineInfo?.equipmentType?.label === 'Smart Fridge';

    if (balance) {
      setSessionBalance(balance);
    }
    setSelectedPaymentMethod(paymentMethod);

    if (paymentMethod.gatewayName === 'CASH') {
      setSessionState('PRODUCT_SELECTION');
      return;
    }

    if (paymentMethod.gatewayName === 'TOKEN'
      && (isEvCharger || isSmartFridge)) {
      setSessionState('PICKUP_PRODUCT');
    } else {
      setSessionState('CONNECTING');
    }
    setSessionStatusCode(0);

    try {
      if (vendingMachine.vendingMachineInfo === undefined) {
        throw new Error('No vending machine selected');
      }

      if (isEvCharger && vendingMachine.vendingMachineInfo?.vendingMachineSlave?.id === undefined) {
        throw new Error('No vending machine slave associated');
      }

      const { session }: OpenSessionResponseType = await openSession(
        vendingMachine.vendingMachineInfo.id,
        paymentMethod,
        isEvCharger ? vendingMachine.vendingMachineInfo?.vendingMachineSlave?.id : undefined,
      );

      setSessionStatusCode(session.state);

      if (sessionStatusCode.current !== SessionStatusCodes.REQUESTED
        && sessionStatusCode.current !== SessionStatusCodes.ESTABLISHED) {
        const error = formatSessionStatus(sessionStatusCode.current);
        setSessionError(error);
        return;
      }

      setSessionId(session.id);
    } catch (err) {
      setSessionError(formatSessionStatus(sessionStatusCode.current));
    } finally {
      // end loading
    }
  };

  return (
    <SessionContext.Provider value={{
      sessionId,
      sessionState,
      setSessionState,
      selectPaymentMethod,
      watchSessionState,
      sessionError,
      selectedPaymentMethod,
      sessionBalance,
      setSessionBalance,
      watchSessionBalance,
      watchSessionError,
      reset,
    }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => useContext(SessionContext);
