import React from 'react';
import {
  useAccordionItem,
  useAccordionItemEffect,
  useHeightTransition,
} from '@szhsin/react-accordion';
import {
  ExpandMore,
} from '@mui/icons-material';
import styles from '../accordion/styles.module.css';

const AccordionItem = ({
  header,
  children,
  itemKey,
  initialEntered,
  disabled,
  headerPaddingY = 0,
  notExpandable = false,
  chevronMarginRight = 'auto',
  highlightColor = '#000000',
}: {
  header: React.ReactNode;
  children: React.ReactNode;
  itemKey?: string | number;
  initialEntered?: boolean;
  disabled?: boolean;
  headerPaddingY?: number;
  notExpandable?: boolean;
  chevronMarginRight?: string;
  highlightColor?: string;
}) => {
  const { itemRef, state, toggle } = useAccordionItemEffect<HTMLDivElement>({
    itemKey,
    initialEntered,
    disabled,
  });
  const { buttonProps, panelProps } = useAccordionItem({
    state,
    toggle: notExpandable ? () => {} : toggle,
    disabled,
  });

  const [transitionStyle, panelRef] = useHeightTransition<HTMLDivElement>(state);

  const { status, isMounted, isEnter } = state;

  return (
    <div className={styles.item} ref={itemRef}>
      <h3 style={{ margin: 0 }}>
        <button
          className={isEnter ? styles.buttonExpanded : styles.button}
          type="button"
          {...buttonProps}
          style={{
            paddingTop: `${headerPaddingY}px`,
            paddingBottom: `${headerPaddingY}px`,
            ...(isEnter ? { color: highlightColor } : {}),
          }}
        >
          {header}
          {notExpandable ? null : (
            <ExpandMore
              className={styles.chevron}
              style={{
                marginRight: chevronMarginRight,
              }}
            />
          )}
        </button>
      </h3>
      {isMounted && (
        // Add an extra `div` around the panel `div` for the
        // height transition to work as intended
        <div
          className={styles.content}
          style={{
            display: status === 'exited' ? 'none' : undefined,
            ...transitionStyle,
            color: highlightColor,
          }}
        >
          <div
            className={styles.panel}
            ref={panelRef}
            {...panelProps}
          >
            {children}
          </div>
          {/* Closing tag of the extra `div` */}
        </div>
      )}
    </div>
  );
};

export default AccordionItem;
