import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';
import BackButton from '../components/BackButton';
import BackgroundImage from '../components/BackgroundImage';
import server, { revalueToken } from '../api/api';

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  max-height: 75vh;
  height: 75vh;
`;

const Message = styled.h3`
  text-align: center;
`;

type TransactionState = {
  id: string,
  state: {
    statusCode: string,
    gatewayStatusCode: string,
    type: string
  }
};

export default () => {
  const history = useHistory();
  const {
    wallet,
    amount,
    gatewayName,
    mobilePhone,
  } = history?.location?.state || {};
  const { t } = useTranslation();
  const [inRevalueSession, setInRevalueSession] = React.useState(true);
  const [message, setMessage] = React.useState('starting_communication');
  const timerRef = useRef<number | null>(null);

  const endRevalueSession = (displayMessage: string) => {
    setInRevalueSession(false);
    setMessage(displayMessage);
    server.offSocket('transaction_state');
    if (timerRef.current === null) {
      timerRef.current = setTimeout(() => {
        history.push('/home-no-vm');
      }, 5000) as unknown as number;
    }
  };

  const handleTransactionState = (
    {
      state: transactionState,
    }: TransactionState,
  ) => {
    if (transactionState.type === 'PURCHASE') {
      if (
        transactionState.statusCode === 'REJECTED'
        || transactionState.statusCode === 'CANCELLED'
      ) {
        endRevalueSession('payment_rejected');
      } else if (
        transactionState.statusCode === 'FAILED'
        || transactionState.statusCode === 'UNKNOWN'
      ) {
        endRevalueSession('payment_failed');
      } else if (transactionState.statusCode === 'TIMED_OUT') {
        endRevalueSession('payment_timed_out');
      }
    } else if (transactionState.type === 'MONEY_IN') {
      if (transactionState.statusCode === 'SUCCEEDED') {
        endRevalueSession('revalued_with_success');
      } else if (transactionState.statusCode === 'FAILED') {
        endRevalueSession('revalue_failed');
      }
    } else if (transactionState.type === 'REFUND') {
      if (transactionState.statusCode === 'SUCCEEDED') {
        endRevalueSession('revalue_failed');
      } else {
        endRevalueSession('revalue_failed_please_contact_support');
      }
    }
  };

  const requestRevalue = async () => {
    try {
      const { state } = await revalueToken(
        wallet.id,
        parseFloat(amount) * 100,
        wallet.currencyCode,
        gatewayName,
        {
          mobilePhone,
        },
      );

      if (state === 'FAILED') {
        endRevalueSession('error_occured');
        return;
      }

      setMessage('please_approve_the_purchase_on_the_app');

      server.onSocket('transaction_state', handleTransactionState);
    } catch (err) {
      endRevalueSession('error_occured');
    }
  };

  useEffect(() => {
    if (!wallet || !gatewayName || !mobilePhone || !amount) {
      history.push('/home-no-vm');
      return () => {
        server.offSocket('transaction_state');
      };
    }

    requestRevalue();

    return () => {
      server.offSocket('transaction_state');
    };
  }, []);

  return (
    <BackgroundImage>
      <Centered>
        { inRevalueSession && <CircularProgress color="inherit" /> }
        <Message>{t(message)}</Message>
      </Centered>
      <div style={{ flex: 1 }} />
      {
        !inRevalueSession && (
        <BackButton backHandler={() => {
          if (timerRef.current !== null) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
          }
          history.push('/home-no-vm');
        }}
        />
        )
      }
    </BackgroundImage>
  );
};
