import React from 'react';
import {
  Document, Page, View, Text, StyleSheet, Image, Font,
} from '@react-pdf/renderer';
import contactless from '../images/contactless.png';

Font.register({
  family: 'Courier',
  src: 'https://fonts.gstatic.com/s/couriernew/v14/ZNQjvObed/nopqrhABCCC6-UXzT0E.ttf',
  fontWeight: 400,
  fontStyle: 'normal',
});

const styles = StyleSheet.create({
  body: {
    padding: 24,
  },
  text: {
    fontSize: 14,
    textAlign: 'left',
    lineHeight: 2,
    fontFamily: 'Courier',
  },
  img: {
    height: 56,
    width: 56,
    margin: 'auto',
    marginBottom: 12,
  },
});

const PdfBuilder = ({ document }) => {
  const textLines = document.text
    .replaceAll(' ', '\u00A0')
    .replaceAll('\r', '\n')
    .split('\n');

  const isContaclessLogoPresent = /@@logo_CTLS@@|\[CTLS_LOGO\]/.test(document.text);

  return (
    <Document>
      <Page
        size={{
          width: 400,
          height: 48 + textLines.length * 27 + (isContaclessLogoPresent ? 29 : 0),
        }}
        wrap={false}
        style={styles.body}
      >
        <View>
          {textLines.map((textLine) => {
            if (/@@logo_CTLS@@|\[CTLS_LOGO\]/.test(textLine)) {
              return (
                <Image
                  src={contactless}
                  style={styles.img}
                />
              );
            }

            return (
              <Text style={styles.text}>
                {textLine}
              </Text>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

export default PdfBuilder;
