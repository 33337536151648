import React from 'react';
import Flicking, {ChangedEvent, MoveEvent} from '@egjs/react-flicking';
import './style.css';

export default function CardSlider({children, onWalletChange}) {
  const flickRef = React.createRef <Flicking>();
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const moveTo = (index: number) => async () => {
    try {
      setCurrentIndex(index);
      await flickRef?.current?.moveTo(index);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div style={{width: '100%'}}>
      <Flicking
        style={{width: '100%', height: '100%'}}
        ref={flickRef}
        viewportTag="div"
        cameraTag="div"
        cameraClass=""
        duration={400}
        align="center"
        renderOnSameKey={false}
        onChanged={(e: ChangedEvent) => {
          setCurrentIndex(e.index);
          onWalletChange(e.index);
        }}
        horizontal
      >
        {
          children?.map((child, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className={`slider-container${index === currentIndex ? ' slider-active' : ''}`}
                 onClick={moveTo(index)}>
              {child}
            </div>
          ))
        }
      </Flicking>
    </div>
  );
}
