import React, { useEffect } from 'react';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import server from '../api/api';
import i18n from '../i18n/i18n';
import { ReactComponent as MBWayLogo } from '../images/MBWAY_PagAutorizados.svg';

const Message = styled.h1`
  text-align: center;
  font: 1.6em Poppins;
`;

const StyledMbWayLogo = styled(MBWayLogo)`
  height: 20%;
`;

function MandateRequestView({ history, location }) {
  const { mandateId, mobilePhone } = location.state || {};

  useEffect(() => {
    const handler = (event) => {
      // Handle mandate result
      if (event.id === mandateId) {
        if (event.state.type === 'AUTHORIZATION'
          && (event.state.statusCode === 'REJECTED'
            || event.state.statusCode === 'SUCCEEDED')) {
          history.push('/mandate-result', { mandateResult: event, mobilePhone });
        }
      }
    };

    server.onSocket('transaction_state', handler);

    return () => {
      server.offSocket('transaction_state', handler);
    };
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      height="100%"
      margin="2vh"
    >
      <StyledMbWayLogo />
      <Message><b><span>{i18n.t('verify_your_mbway_app')}</span></b></Message>
      <span style={{ marginBottom: '20px' }}>{i18n.t('approve_mandate_mbway')}</span>
      <ClipLoader loading size={50} />
    </Box>

  );
}

export default MandateRequestView;
