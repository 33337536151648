import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

const FormContainer = styled.div`
  flex:1
`;

const TextFieldContainer = styled.div`
  color: rgb(0,0,0);
  display: flex;
  min-height: 60px;
  max-height: 60px;
  margin-bottom: 5px;
`;

const StyledTextField = styled(TextField)({
  '& .MuiInput-underline': {
    '&:before': {
      borderBottom: '1px solid rgba(77,76,77,255)',
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: '1px solid rgba(77,76,77,255)',
    },
    '&:after': {
      borderBottom: '1px solid rgba(77,76,77,255)',
    },
  },
  '& .MuiFormLabel-root': {
    color: 'rgba(77,76,77,255)',
  },

  '& .MuiFormLabel-root.Mui-error': {
    color: ' rgba(77,76,77,255) !important',
  },
  '& .MuiInput-underline.Mui-error:after': {
    borderBottomColor: 'rgba(77,76,77,255) !important',
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: '#CCD5D5 !important',
  },
  '& .MuiFormLabel-root.Mui-focused': {
    color: 'rgba(77,76,77,255)',
  },

});

export {
  FormContainer,
  TextFieldContainer,
  StyledTextField,
};
