import React from 'react';
import CancelRounded from '@material-ui/icons/CancelRounded';
import styled from 'styled-components';

const StyledDiv = styled.div`
  background-color: white;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  text-align: center;
  border-radius: 10px;
  color: black;
  padding: 10px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorMessage = ({ error }) => (
  <StyledDiv>
    <CancelRounded style={{
      color: 'red',
      marginRight: '8px',
      verticalAlign: 'middle',
    }}
    />
    {error}
  </StyledDiv>
);

export default ErrorMessage;
