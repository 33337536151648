import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import * as qs from 'qs';
import CardButton from '../components/CardButton';
import { useVendingMachine } from '../contexts/VendingMachineContext';
import { useSession } from '../contexts/SessionContext';
import { ReactComponent as MBWayLogo } from '../images/logo_mbway.svg';
import { ReactComponent as CardLogo } from '../images/credit_card2.svg';
import Token from '../images/token.png';
import BackButton from '../components/BackButton';
import BackgroundImage from '../components/BackgroundImage';
import i18n from '../i18n/i18n';
import TopHeader from '../components/TopHeader';

const StyledHeader = styled.h2`
  margin-bottom: 0;
`;

const OptionsContainer = styled.div`
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  overflow-y: auto;
  position: relative;
  padding: 2% 0;
  color: rbg(255, 255, 255);
`;

const PaymentMethodButton = styled(CardButton)`
  background-color: rgb(255, 255, 255);
  margin: 0.2em;
  border: 1px solid rgb(250,194,3);
  width: calc(50% - 0.4em);
  color: rgb(250,194,3);
  font-weight: bold;
`;

const StyledMbWayLogo = styled(MBWayLogo)`
  height: 35%;
`;

const StyledCardLogo = styled(CardLogo)`
  height: 70%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  width: 96%;
  padding: 2%;
`;

export default ({ location }) => {
  const { vendingMachineInfo, stickerId } = useVendingMachine();
  const { sessionState, setSessionState, selectPaymentMethod } = useSession();
  const history = useHistory();
  const {
    wallet,
  } = history?.location?.state || {};

  const { s } = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    if (!vendingMachineInfo && !s) {
      history.push('/home-no-vm');
    } else if (!vendingMachineInfo && s) {
      history.push(`/load?s=${s}`);
    }

    if (sessionState !== 'PAYMENT_METHOD_SELECTION') {
      setSessionState('PAYMENT_METHOD_SELECTION');
    }
  }, []);

  const drawPaymentMethods = () => {
    if (vendingMachineInfo && vendingMachineInfo.paymentTerminals) {
      return vendingMachineInfo.paymentTerminals.map((paymentMethod: any) => {
        switch (paymentMethod.gatewayName) {
          case 'SPG':
            return (
              <PaymentMethodButton
                key={paymentMethod.id}
                onClick={() => {
                  history.push(`/mbway?s=${stickerId}`);
                }}
              >
                <StyledMbWayLogo />
              </PaymentMethodButton>
            );

          case 'CARD':
            return (
              <PaymentMethodButton
                key={paymentMethod.id}
                onClick={() => {
                  selectPaymentMethod({
                    gatewayName: 'CARD',
                    peripheralId: paymentMethod.peripheralId,
                  });
                  history.push(`/product-selection?s=${stickerId}`);
                }}
              >
                <StyledCardLogo />
              </PaymentMethodButton>
            );

          default:
            return null;
        }
      });
    }

    return (<span>{i18n.t('no_payment_methods_available')}</span>);
  };

  return (
    <BackgroundImage equipmentTypeId={vendingMachineInfo?.equipmentType.id}>
      <TopHeader showVm userLogo />
      <Container>
        <StyledHeader>{i18n.t('select_payment_method')}</StyledHeader>
        <OptionsContainer>
          {wallet && (
            <PaymentMethodButton
              key={wallet?.id}
              onClick={() => {
                selectPaymentMethod({
                  gatewayName: 'TOKEN',
                  gatewayParameters: { walletId: wallet?.id },
                  maxCredit: wallet?.maxCredit,
                }, wallet?.balance);
                history.push(`/product-selection?s=${stickerId}`);
              }}
            >
              <img alt="token" width={80} src={Token} />
              {wallet.number}
            </PaymentMethodButton>
          )}
          {drawPaymentMethods()}
        </OptionsContainer>
        <div style={{ flex: 1 }} />
        <BackButton screen={`/home${s ? `?s=${s}` : ''}`} />
      </Container>
    </BackgroundImage>
  );
};
