import React from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { CheckCircle } from '@mui/icons-material';
import { useSession } from '../contexts/SessionContext';
import i18n from '../i18n/i18n';
import { ReactComponent as MBWayLogo } from '../images/MBWAY_PagAutorizados.svg';

const StyledMbWayLogo = styled(MBWayLogo)`
  height: 20%;
`;

const StyledButton = styled(({ ...rest }) => (
  <Button
    {...rest}
  />
))`
  && {
    background-color: rgb(52, 92, 68);
    border-width: 0;
    border-radius: 10px;
    padding: 10px 20px;
    margin: 10px;
    width: 140px;
    text-transform: capitalize;
    font: 0.85em Poppins;

    ${({ disabled }) => disabled && `
     background-color: rgb(243, 243, 243);
     color: rgba(255,255,255,0.15)
     border-width: 0;
     border-radius: 10px;
     padding: 10px 20px;
     margin: 10px;
     width: 120px;
     text-transform: capitalize;
     font: 0.85em Poppins;
  `
}
`;

const Message = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const StyledSpan = styled.span`
  text-align: center;
  font-size: 0.85em;
`;

function MandateResultView({ history, location }) {
  const { selectPaymentMethod } = useSession();

  const { mandateResult, mobilePhone } = location.state || {};

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      height="100%"
      margin="2vh"
    >
      <StyledMbWayLogo />
      <Message>
        {mandateResult.state.statusCode === 'SUCCEEDED'
          ? (
            <>
              <CheckCircle sx={{ color: 'green', fontSize: '50px' }} />
              <Message><b>{i18n.t('authorized_payments_added')}</b></Message>
              <StyledSpan>{i18n.t('authorized_payments_added_confirmation')}</StyledSpan>
            </>
          )
          : <span>{i18n.t('authorized_payment_rejected')}</span>}
      </Message>
      <StyledButton
        variant="outlined"
        color="secondary"
        type="submit"
        form="profile"
        onClick={() => {
          selectPaymentMethod({
            gatewayName: 'SPG',
            gatewayParameters: {
              mobilePhone,
            },
          });

          history.push('/product-selection');
        }}
      >
        {i18n.t('proceed')}
      </StyledButton>
    </Box>
  );
}

export default MandateResultView;
