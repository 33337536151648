import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import zxcvbn from 'zxcvbn';
import {
  RouteProps,
} from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  FormContainer,
  TextFieldContainer,
  StyledTextField,
} from './FormStyles';
import { emailRegex } from '../../utils/Regex';
import { register } from '../../../src/api/api';
import { useVendingMachine } from '../../contexts/VendingMachineContext';
import { passwordStrengthColors, passwordStrengthLabels } from '../../constants/User';
import NotificationToast from '../notifications/NotificationToast';
import i18n from '../../i18n/i18n';

type Error = {
  name: string | null,
  email: string | null,
  password: string | null,
  confirmPassword: string | null,
}

type RoutePropsTypes = RouteProps<string> & {
  handleError: () => void
}

const StyledButton = styled(({ ...rest }) => (
  <Button
    {...rest}
  />
))`
  && {
    background-color: rgb(250,194,3);
    border-width: 0;
    border-radius: 10px;
    padding: 10px 20px;
    margin: 10px;
    width: 120px;
    text-transform: capitalize;
    font: 0.85em Poppins;

    ${({ disabled }) => disabled && `
     background-color: rgb(243, 243, 243);
     color: rgba(255,255,255,0.15)
     border-width: 0;
     border-radius: 10px;
     padding: 10px 20px;
     margin: 10px;
     width: 120px;
     text-transform: capitalize;
     font: 0.85em Poppins;
  `
}
`;

const StyledDiv = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex:1;
`;

const StyledLink = styled.a`
 text-decoration: underline;
  color: black;
`;

const StyledCheckbox = styled(({ ...rest }) => (
  <Checkbox
    {...rest}
  />
))`
  && {
  ${({ checked }) => checked && `
     color: rgb(250,194,3) !important;
  `
}
`;

const RegisterForm = ({ history, initialState }:RoutePropsTypes) => {
  const [name, setName] = React.useState<string | null>(null);
  const [email, setEmail] = React.useState<string | null>(initialState?.email);
  const [password, setPassword] = React.useState<string | null>(initialState?.password);
  const [confirmPassword, setConfirmPassword] = React.useState<string | null>(null);
  const [acceptedTerms, setAcceptedTerms] = React.useState<boolean>(false);
  const [error, setError] = React.useState<Error>({
    name: null,
    email: null,
    password: null,
    confirmPassword: null,
  });
  const [hasError, setHasError] = React.useState(false);
  const [notification, setNotification] = useState<any>(null);
  const [showNotification, setShowNotification] = useState(false);

  const handleError = useCallback((newError) => {
    setHasError(newError);
  }, []);

  const { stickerId } = useVendingMachine();

  const getPasswordStrength = (text?: string | null) => {
    const score = Math.min(zxcvbn(text).score, 3);
    return {
      score,
      message: i18n.t(passwordStrengthLabels[score]),
      color: passwordStrengthColors[score],
    };
  };

  useEffect(() => {
    const newErrors = {
      ...error,
    };

    if (!name) {
      newErrors.name = i18n.t('mandatory');
      handleError(true);
    } else {
      newErrors.name = null;
      handleError(false);
    }

    if (!email || email === '') {
      newErrors.email = i18n.t('mandatory');
      handleError(true);
    }

    if (email && !emailRegex.test(email)) {
      newErrors.email = i18n.t('invalid_email');
      handleError(true);
    } else if (newErrors.email !== i18n.t('mandatory')) {
      newErrors.email = null;
      handleError(false);
    }

    if (!password || password === '') {
      newErrors.password = i18n.t('mandatory');
      handleError(true);
    }

    if (password && getPasswordStrength(password).score < 3) {
      newErrors.password = i18n.t('password_too_simple');
      handleError(true);
    } else if (newErrors.password !== i18n.t('mandatory')) {
      newErrors.password = null;
      handleError(false);
    }

    if (password && password !== confirmPassword) {
      handleError(true);
      newErrors.confirmPassword = i18n.t('different_password');
    } else {
      newErrors.confirmPassword = null;
      if (newErrors.password === null) {
        handleError(false);
      }
    }

    if (!email?.length || !password?.length || !confirmPassword?.length || !name?.length) {
      handleError(true);
    }

    setError(newErrors);
  }, [name, email, password, confirmPassword, handleError]);

  const onSubmit = async (e) => {
    if (acceptedTerms === false) {
      setNotification({
        severity: 'error',
        title: i18n.t('error'),
        message: i18n.t('must_accept_terms'),
      });
      setShowNotification(true);
    }

    e.preventDefault();

    if (acceptedTerms) {
      try {
        const trimmedEmail = email ? email.trim() : '';
        if (stickerId) {
          await register(name, trimmedEmail, password, stickerId);
        } else {
          await register(name, trimmedEmail, password, undefined);
        }
        handleError(false);
        setNotification({
          severity: 'success',
          title: i18n.t('success'),
          message: i18n.t('check_email'),
        });
        setShowNotification(true);
        setTimeout(() => history.push('home'), 5000);
      } catch (ex) {
        // @ts-ignore
        if (ex.body.message === 'EMAIL_IN_USE') {
          setNotification({
            severity: 'error',
            title: i18n.t('error'),
            message: i18n.t('email_already_exists'),
          });
          setShowNotification(true);
        } else {
          setNotification({
            severity: 'error',
            title: i18n.t('error'),
            message: i18n.t('error_occured_try_later'),
          });
          setShowNotification(true);
        }
      }
    }
  };

  let passwordStrength;
  if (password) {
    passwordStrength = getPasswordStrength(password);
  }

  return (
    <FormContainer>
      <NotificationToast
        open={showNotification}
        message={notification && notification.message}
        title={notification && notification.title}
        severity={notification && notification.severity}
        onClose={() => setShowNotification(false)}
      />
      <form noValidate autoComplete="off" onSubmit={onSubmit} id="register" style={{ color: 'rgba(77,76,77,255)' }}>
        <StyledDiv>
          <span>{i18n.t('name')}</span>
          <StyledTextField
            fullWidth
            onChange={(e) => setName(e.target.value)}
            error={!!error?.name}
            id="name"
            value={name}
            helperText={error.name ? error.name : ''}
          />
        </StyledDiv>
        <StyledDiv>
          <span>Email</span>
          <StyledTextField
            fullWidth
            onChange={(e) => setEmail(e.target.value)}
            error={!!error?.email}
            id="email"
            value={email}
            helperText={error.email ? error.email : ''}
          />
        </StyledDiv>
        <StyledDiv>
          <span>{i18n.t('password')}</span>
          <StyledTextField
            fullWidth
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            error={!!error.password}
            id="password"
            helperText={(
              <>
                {(
                  password && (
                    <LinearProgress
                      style={{backgroundColor: passwordStrength.color}}
                      value={100 - (passwordStrength.score + 1) * 25}
                      variant="determinate"
                    />
                  )
                )}
                {error.password ? error.password : ' '}
              </>
            )}
            InputProps={{
              endAdornment: password ? (
                <InputAdornment position="end">
                  <span style={{color: passwordStrength.color}}>
                    {passwordStrength.message}
                  </span>
                </InputAdornment>
              ) : null,
            }}
          />
        </StyledDiv>
        <StyledDiv>
          <span>{i18n.t('confirm_password')}</span>
          <StyledTextField
            fullWidth
            type="password"
            color="secondary"
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={!!error.confirmPassword}
            id="confirmPassword"
            helperText={error.confirmPassword ? error.confirmPassword : ''}
          />
        </StyledDiv>
        <TextFieldContainer>
          <StyledCheckbox
            checked={acceptedTerms}
            onChange={(event) => {
              setAcceptedTerms(event.currentTarget.checked);
            }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <small>
              {i18n.t('terms_and_conditions1')}
              &nbsp;
              <StyledLink
                href="https://vendiot-static.elecctro.com/customer/privacy_policy.html"
              >
                {i18n.t('terms_and_conditions2')}
              </StyledLink>
            </small>
          </div>
        </TextFieldContainer>
        <ButtonsContainer>
          <StyledButton
            variant="outlined"
            color="secondary"
            type="submit"
            form="register"
            disabled={hasError}
          >
            {i18n.t('register')}
          </StyledButton>
        </ButtonsContainer>
      </form>
    </FormContainer>
  );
};

export default withRouter(RegisterForm);
