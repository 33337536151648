import styled from 'styled-components';

export default styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 45px;
  margin: 0;
  padding: 0 0 0 20px;
  border: 0;
  border-radius: 16px;
  text-decoration: none;
  color: white;
  background-color: rgba(255, 255, 255, 0.2);
  text-align: center;
  cursor: pointer;
  outline: none;
  font-size: 1.2em;
  font-weight: 500;
  user-select: none;
  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
  &:active {
    background-color: rgba(255, 255, 255, 0.6);
    outline: none;
  }
  &:focus {
    background-color: rgba(255, 255, 255, 0.4);
    outline: none;
  }
`;
