import React from 'react';
import styled from 'styled-components';
import { Box, CircularProgress } from '@material-ui/core';
import PrivateCard from '../private-card';
import i18n from '../../i18n/i18n';
import BackButton from '../BackButton';
import CardSlider from '../card-slider';
import { useVendingMachine } from '../../contexts/VendingMachineContext';

const CONTAINER_STYLE = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: middle;
  height: 13em;
`;

const PrivateCardSlider = ({
  paymentMethods,
  loading,
  showNotification,
  onWalletChange,
  isInVendingMachine,
  color,
  onClick = (index) => {
    // eslint-disable-next-line no-console
    console.log(index);
  },
}) => {
  const { vendingMachineInfo } = useVendingMachine();

  if (loading) {
    return (
      <Box
        display="flex"
        width="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        height="100%"
      >
        {
          !showNotification && (
            <CircularProgress color="inherit" />
          )
        }
        {
          showNotification && (
            <>
              <div style={{ flex: 1 }} />
              <h2 style={{ textAlign: 'center' }}>{i18n.t('error_occured')}</h2>
              <h2 style={{ textAlign: 'center' }}>{i18n.t('try_again_later')}</h2>
              <div style={{ flex: 1 }} />
              <div style={{ width: '100%' }}>
                <BackButton screen="home-no-vm" style={{ float: 'left' }} />
              </div>
            </>
          )
        }
      </Box>
    );
  }

  const handleWalletChange = (index) => {
    onWalletChange(paymentMethods[index]);
  };

  const onEvClick = (index) => {
    onClick(index);
  };

  const renderEv = () => (

    <div style={{
      width: '80%',
      height: '80%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: '41px',
      margin: 'auto',
    }}
    >
      <h1 style={{ zIndex: 2, textAlign: 'center' }}>Choose Your Payment Method</h1>
      {paymentMethods?.map((paymentMethod, i) => {
        switch (paymentMethod.gatewayName) {
          case 'TOKEN': {
            const amount = isInVendingMachine ? paymentMethod.wallet.balance : paymentMethod.wallet.amount;
            const walletAmount = paymentMethod.wallet.currencyCode === 'XXX' ? amount : (amount / 100).toFixed(2);

            return (
              <PrivateCard
                onClick={() => { onEvClick(i); }}
                key={paymentMethod?.wallet?.id}
                paymentMethod={paymentMethod}
                walletAmount={walletAmount}
                color={color}
              />
            );
          }
          case 'SPG':
            return (
              <PrivateCard
                onClick={() => { onEvClick(i); }}
                key={paymentMethod.gatewayName}
                paymentMethod={paymentMethod}
                color={color}
              />
            );
          case 'SPGCARD':
            return (
              <PrivateCard
                onClick={() => { onEvClick(i); }}
                key={paymentMethod.gatewayName}
                paymentMethod={paymentMethod}
                color={color}
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );

  const renderNormal = () => (
    <CONTAINER_STYLE>
      <CardSlider onWalletChange={handleWalletChange}>
        {paymentMethods?.map((paymentMethod) => {
          switch (paymentMethod.gatewayName) {
            case 'TOKEN': {
              const amount = isInVendingMachine ? paymentMethod.wallet.balance : paymentMethod.wallet.amount;
              const walletAmount = paymentMethod.wallet.currencyCode === 'XXX' ? amount : (amount / 100).toFixed(2);

              return (
                <PrivateCard
                  onClick={onClick}
                  key={paymentMethod?.wallet?.id}
                  paymentMethod={paymentMethod}
                  walletAmount={walletAmount}
                  color={color}
                />
              );
            }
            case 'SPG':
              return (
                <PrivateCard
                  onClick={onClick}
                  key={paymentMethod.gatewayName}
                  paymentMethod={paymentMethod}
                  color={color}
                />
              );
            case 'SPGCARD':
              return (
                <PrivateCard
                  onClick={onClick}
                  key={paymentMethod.gatewayName}
                  paymentMethod={paymentMethod}
                  color={color}
                />
              );
            default:
              return null;
          }
        })}
      </CardSlider>
    </CONTAINER_STYLE>
  );

  return vendingMachineInfo?.equipmentType?.label === 'EV Charger' ? renderEv() : renderNormal();
};

export default PrivateCardSlider;
