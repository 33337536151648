import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import RegisterForm from '../components/forms/RegisterForm';
import Footer from '../components/Footer';
import Logo from '../images/logo_elecctro_colored.png';
import i18n from '../i18n/i18n';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  margin: 15px;
  overflow-y: scroll;
`;

const LogoContainer = styled.div`
  height: 26px;
  text-align: center;
  font-size: 0.4rem;
  vertical-align: middle;
`;

const RegisterView = ({ history }) => (
  <Root>
    <div style={{ flex: 1 }}>
      <LogoContainer>
        <img
          src={Logo}
          alt="Logo"
          style={{ height: '240%', display: 'inline-block', verticalAlign: 'middle' }}
        />
      </LogoContainer>
      <div style={{ marginTop: '120px' }}>
        <h1>{i18n.t('register')}</h1>
        <RegisterForm initialState={history?.location?.state} />
      </div>
    </div>
    <Footer showBackButton screen="/" />
  </Root>
);

export default withRouter(RegisterView);
