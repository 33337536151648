const fixZeros = (number) => (number < 10 ? `0${number}` : number);

const formatDate = (dateStr: string, includeSeconds = true) => {
  const date = new Date(Date.parse(dateStr));
  return `${fixZeros(
    date.getDate(),
  )}/${fixZeros(
    date.getMonth() + 1,
  )}/${fixZeros(date.getFullYear())} ${fixZeros(
    date.getHours(),
  )}:${fixZeros(
    date.getMinutes(),
  )}${includeSeconds ? `:${fixZeros(
    date.getSeconds(),
  )}` : ''}`;
};

export const getDate = (dateStr: string) => {
  const date = new Date(Date.parse(dateStr));
  return `${fixZeros(
    date.getDate(),
  )}/${fixZeros(
    date.getMonth() + 1,
  )}/${fixZeros(date.getFullYear())}`;
};

export const getTime = (dateStr: string, includeSeconds = true) => {
  const date = new Date(Date.parse(dateStr));
  return `${fixZeros(
    date.getHours(),
  )}:${fixZeros(
    date.getMinutes(),
  )}${includeSeconds ? `:${fixZeros(date.getSeconds())}` : ''}`;
};

export default formatDate;
