import React, { useState } from 'react';
import { ValidateResult, useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import zxcvbn from 'zxcvbn';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import * as qs from 'qs';
import { withStyles } from '@material-ui/core';
import {
  passwordStrengthColors,
  passwordStrengthLabels,
} from '../../constants/User';
import { confirmPasswordReset } from '../../api/api';
import NotificationToast from '../notifications/NotificationToast';
import {
  FormContainer,
  StyledTextField,
} from './FormStyles';
import i18n from '../../i18n/i18n';

const style = (theme) => ({
  circularProgress: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
  passwordStrength: {
    backgroundColor: 'grey',
    position: 'relative',
    bottom: '4px',
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    '&[strength=strength0] > div': {
      backgroundColor: `${passwordStrengthColors[0]} !important`,
    },
    '&[strength=strength1] > div': {
      backgroundColor: `${passwordStrengthColors[1]} !important`,
    },
    '&[strength=strength2] > div': {
      backgroundColor: `${passwordStrengthColors[2]} !important`,
    },
    '&[strength=strength3] > div': {
      backgroundColor: `${passwordStrengthColors[3]} !important`,
    },
  },
});

const getPasswordStrength = (password = '') => {
  const score = Math.min(zxcvbn(password).score, 3);
  return {
    score,
    message: i18n.t(passwordStrengthLabels[score]),
    color: passwordStrengthColors[score],
  };
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
`;

const Label = styled.span`
  display: block;
  margin-bottom: 35px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

const StyledButton = styled(({ ...rest }) => (
  <Button
    {...rest}
  />
))`
  && {
    background-color: rgb(250,194,3);
    border-width: 0;
    border-radius: 10px;
    padding: 10px 20px;
    margin: 10px;
    width: 120px;
    text-transform: capitalize;
    font: 0.85em Poppins;

    ${({ disabled }) => disabled && `
     background-color: rgb(243, 243, 243);
     color: rgba(255,255,255,0.15)
     border-width: 0;
     border-radius: 10px;
     padding: 10px 20px;
     margin: 10px;
     width: 120px;
     text-transform: capitalize;
     font: 0.85em Poppins;
  `
}
`;

const StyledDiv = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
`;

const ResetPasswordForm = ({ location, history }) => {
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState<any>(null);
  const [showNotification, setShowNotification] = useState(false);
  const [submitButtonEnabled, setSubmitButtonEnable] = useState(true);

  const { token } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
    getValues,
  } = useForm({ mode: 'onBlur' });

  const onSubmit: any = async ({ password, confirmPassword }) => {
    setSubmitButtonEnable(false);

    let isOk = true;
    if (zxcvbn(password).score < 3) {
      isOk = false;
      // @ts-ignore
      setError('password', 'tooSimple', <span>{i18n.t('password_too_simple')}</span>);
    }
    if (password !== confirmPassword) {
      isOk = false;
      // @ts-ignore
      setError('confirmPassword', 'noMatch', i18n.t('passwords_do_not_match'));
    }

    if (!isOk) {
      return;
    }

    setLoading(true);

    try {
      await confirmPasswordReset(token, password);
      setLoading(false);
      setNotification({
        severity: 'success',
        title: i18n.t('success'),
        message: i18n.t('reset_password_successfully'),
      });
      setShowNotification(true);
      setTimeout(() => history.push('/'), 5000);
    } catch (err: any) {
      setSubmitButtonEnable(true);
      setLoading(false);
      if (err.response
                && err.response.data
                && err.response.data.message) {
        switch (err.response.data.message) {
          case 'INVALID_TOKEN':
            setNotification({
              severity: 'error',
              title: i18n.t('error'),
              message: i18n.t('no_password_reset_record_found'),
            });
            setShowNotification(true);
            return;

          case 'EXPIRED_TOKEN':
            setNotification({
              severity: 'error',
              title: i18n.t('error'),
              message: i18n.t('max_time_reset_exceeded'),
            });
            setShowNotification(true);
            return;

          case 'PASSWORD_TOO_SIMPLE':
            setError(
              'password',
              // @ts-ignore
              'tooSimple',
              i18n.t('password_too_simple'),
            );
            return;

          default:
            break;
        }

        setNotification({
          severity: 'error',
          title: i18n.t('error'),
          message: i18n.t('error_occured_try_later'),
        });
        setShowNotification(true);
      } else {
        setNotification({
          severity: 'error',
          title: i18n.t('error'),
          message: i18n.t('error_occured_try_later'),
        });
        setShowNotification(true);
      }
    }
  };

  const password = watch('password');
  const passwordStrength = getPasswordStrength(password);

  return (
    <Root style={{ margin: '0 2vh 2vh 2vh' }}>
      <NotificationToast
        open={showNotification}
        message={notification && notification.message}
        title={notification && notification.title}
        severity={notification && notification.severity}
        onClose={() => setShowNotification(false)}
      />
      <h2>{i18n.t('password_recovery')}</h2>
      <Label>{i18n.t('reset_password_form')}</Label>
      <FormContainer
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'start',
          height: '100%',
        }}
      >
        <form noValidate autoComplete="off">
          <StyledDiv>
            <span>{i18n.t('insert_password')}</span>
            <StyledTextField
              fullWidth
              error={!!errors.password}
              {...register('password', {
                validate: () => {
                  const valid = passwordStrength.score >= 3;
                  if (!valid) {
                    return i18n.t('password_too_simple') as ValidateResult;
                  }
                  return valid;
                },
              })}
              helperText={(
                <div>
                  {(
                    password && (
                      <LinearProgress
                        style={{ backgroundColor: passwordStrength.color }}
                        value={100 - (passwordStrength.score + 1) * 25}
                        variant="determinate"
                      />
                    )
                  )}
                  {errors.password ? errors.password.message : ' '}
                </div>
              )}
              InputProps={{
                endAdornment: password ? (
                  <InputAdornment position="end">
                    <span style={{ color: passwordStrength.color }}>
                      {passwordStrength.message}
                    </span>
                  </InputAdornment>
                ) : null,
              }}
              name="password"
              type="password"
            />
          </StyledDiv>
          <StyledDiv>
            <span>{i18n.t('repeat_password')}</span>
            <StyledTextField
              fullWidth
              error={!!errors.confirmPassword}
              {...register('confirmPassword', {
                validate: (value) => {
                  const valid = getValues().password === value;
                  if (!valid) {
                    return i18n.t('passwords_do_not_match') as ValidateResult;
                  }
                  return valid;
                },
              })}
              helperText={
                (
                  <div>
                    {errors.confirmPassword ? errors.confirmPassword.message : ''}
                  </div>
                )
              }
              name="confirmPassword"
              type="password"
            />
          </StyledDiv>
          <ButtonsContainer>
            <StyledButton
              variant="outlined"
              color="secondary"
              disabled={!submitButtonEnabled || Object.keys(errors).length > 0}
              onClick={handleSubmit(onSubmit)}
            >
              {i18n.t('save')}
            </StyledButton>
            {loading && <CircularProgress size={24} />}
          </ButtonsContainer>
        </form>
      </FormContainer>
    </Root>
  );
};

// @ts-ignore
export default withRouter(withStyles(style)(ResetPasswordForm));
