import React from 'react';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import CloseIcon from '@material-ui/icons/Close';
import i18n from '../../i18n/i18n';

const SlideTransition = (props) => <Slide {...props} direction="up" />;
const NotificationToast: any = ({
  duration,
  message,
  onClose,
  open,
  severity,
  title,
}) => (
  <Snackbar
    action={(
      <Button
        onClick={onClose}
      >
        <CloseIcon fontSize="small" />
      </Button>
              )}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    autoHideDuration={duration === undefined ? 5000 : duration}
    onClose={onClose}
    open={open}
    TransitionComponent={SlideTransition}
  >
    <Alert
      onClose={onClose}
      severity={severity || 'error'}
    >
      <AlertTitle>{title || i18n.t("operation_failed")}</AlertTitle>
      {message}
    </Alert>
  </Snackbar>
);

export default NotificationToast;
