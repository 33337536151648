import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import EmailOutlined from '@material-ui/icons/EmailOutlined';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import {
  StyledTextField,
} from './FormStyles'; import { resetPassword } from '../../api/api';
import NotificationToast from '../notifications/NotificationToast';
import i18n from '../../i18n/i18n';


const StyledButton = styled(({ ...rest }) => (
  <Button
    {...rest}
  />
))`  
&& {
    text-transform: capitalize;
  }
`;

const ResetPasswordDialog = ({ onClose, open, email }) => {
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState<any>(null);
  const [showNotification, setShowNotification] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  
  const onSubmit: any = async ({ email }) => {
    setLoading(true);

    try {
      await resetPassword(email);
      setLoading(false);
      setNotification({
        severity: 'success',
        title: i18n.t("success"),
        message: i18n.t("check_email"),
      });
      setShowNotification(true);
      onClose();
    } catch (err) {
      setLoading(false);
      setNotification({
        severity: 'error',
        title: i18n.t("error"),
        message: i18n.t("error_occured_try_later"),
      });
      setShowNotification(true);
    }
  };

  return (
    <>
      <NotificationToast
        open={showNotification}
        message={notification && notification.message}
        title={notification && notification.title}
        severity={notification && notification.severity}
        onClose={() => setShowNotification(false)}
      />
      <Dialog onClose={onClose} open={open}>
        <DialogContent>
          <h2>{i18n.t("reset_password")}</h2>
          <Typography>
            {i18n.t("fill_email")}
            <br />
            <br />
            {i18n.t("email_reset_password")}
          </Typography>
          <StyledTextField
            {...register('email', {
              required: i18n.t("mandatory") as string,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: i18n.t("invalid_email"),
              },
            })}
            autoFocus
            error={!!errors.email}
            fullWidth
            helperText={errors.email ? errors.email.message : ' '}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlined />
                </InputAdornment>
              ),
            }}
            name="email"
            placeholder="Email"
            size="small"
            defaultValue={email || ''}
            type="email"
            variant="outlined"
            style={{ marginTop: '10px' }}
          />
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={() => onClose()}>
            {i18n.t("cancel")}
          </StyledButton>
          <div>
            <StyledButton
              disabled={loading}
              onClick={handleSubmit(onSubmit)}
              variant="outlined"
              color="secondary"
              style={{backgroundColor: "#f0b239"}}
            >
              {i18n.t("send")}
            </StyledButton>
            {loading && (
            <CircularProgress size={18} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default (ResetPasswordDialog);
