import styled from 'styled-components';
import React, { PropsWithChildren } from 'react';

const MARGIN = '15px';

const Root = styled.button`
  position: fixed;
  width: 45px;
  height: 45px;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 45px;
  text-decoration: none;
  color: white;
  background-color: rgba(250,194,3, 0.5);
  text-align: center;
  cursor: pointer;
  outline: none;
  font-size: 45px;
  font-family: Monospaced, sans-serif;
  &:hover {
    background-color: rgba(250,194,3, 0.4);
  }
  &:active {
    background-color: rgba(250,194,3, 0.6);
    outline: none;
  }
  &:focus {
    background-color: rgba(250,194,3, 0.4);
    outline: none;
  }
`;

type PositionType = 'topleft' | 'topright' | 'bottomleft' | 'bottomright';

const getStyles = (position: PositionType) => {
  switch (position) {
    default:
    case 'topleft':
      return {
        top: MARGIN,
        left: MARGIN,
      };

    case 'topright':
      return {
        top: MARGIN,
        right: MARGIN,
      };

    case 'bottomleft':
      return {
        bottom: MARGIN,
        left: MARGIN,
      };

    case 'bottomright':
      return {
        bottom: MARGIN,
        right: MARGIN,
      };
  }
};

type PropsType = {
  position?: PositionType;
  onClick: () => void;
};

export default ({
  position = 'topleft',
  onClick,
  children,
}: PropsWithChildren<PropsType>) => (
  <Root onClick={onClick} style={getStyles(position)}>
    {children}
  </Root>
);
