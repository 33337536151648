import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import * as qs from 'qs';
import { activateAccount } from '../api/api';
import i18n from '../i18n/i18n';

export default ({ location, history }) => {
  const { token, s } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [activating, setActivating] = useState<boolean>(true);

  const [message, setMessage] = useState<string>('activating_account');

  useEffect(() => {
    (async () => {
      try {
        if (s) {
          await activateAccount(token, s);
        } else {
          await activateAccount(token, undefined);
        }
        setMessage('account_sucessfully_activated');
        const route = s ? `/load?s=${s}` : '/home-no-vm';
        setTimeout(() => history.push(route), 2000);
      } catch (err: any) {
        if (err.statusCode === 400 && err.message === 'INVALID_TOKEN') {
          setMessage('account_already_active');
        } else {
          setMessage('activation_not_possible');
        }
      }
      setActivating(false);
    })();
  }, []);

  return (
    <div style={{
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    }}
    >
      <Box
        display="flex"
        alignItems="center"
        alignContent="center"
        margin="0 auto"
        flexDirection="column"
        justifyContent="center"
        width="100%"
        height="100%"
      >
        <div style={{ flex: 1 }} />
        <h2 style={{ textAlign: 'center' }}>{i18n.t(message)}</h2>
        {activating && <CircularProgress hidden={activating} variant="indeterminate" />}
        <div style={{ flex: 1 }} />
      </Box>
    </div>
  );
};
