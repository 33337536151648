import React from 'react';
import styled from 'styled-components';
import { MuiThemeProvider, unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import { Route, Switch } from 'react-router';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import { UserProvider } from './contexts/UserContext';
import { VendingMachineProvider } from './contexts/VendingMachineContext';
import './App.css';
import LoginView from './views/LoginView';
import RegisterView from './views/RegisterView';
import LanguagesView from './views/LanguagesView';
import ProfileView from './views/ProfileView';
import ChangePasswordView from './views/ChangePasswordView';
import ProductListView from './views/ProductListView';
import PaymentMethodView from './views/PaymentMethodView';
import { SessionProvider } from './contexts/SessionContext';
import PurchasesHistoryView from './views/PurchasesHistoryView';
import LoadVendingMachineInfo from './components/LoadVendingMachineInfo';
import ProductSelectionView from './views/ProductSelectionView';
import MandateRequestView from './views/MandateRequestView';
import MandateResultView from './views/MandateResultView';
import ResetPasswordForm from './components/forms/ResetPassworsForm';
import MBWayMobilePhone from './views/MBWayMobilePhone';
import ActivateAccount from './views/ActivateAccount';
import SessionDocumentsView from './views/SessionDocumentsView';
import RevalueSession from './views/RevalueSession';
import RevalueSelection from './views/RevalueSelection';
import VMView from './views/VMView';
import NoVMView from './views/NoVMView';
import ReadQR from './views/ReadQR';
import { IconContextProvider } from './contexts/IconContext';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgb(40, 40, 40)',
    },
    secondary: {
      main: 'rgb(255,255,255)',
    },
  },
});

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
`;

const StateContainer = styled.div`
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
`;

const App = () => (
  <Root>
    <MuiThemeProvider theme={theme}>
      <IconContextProvider>
        <VendingMachineProvider>
          <UserProvider>
            <SessionProvider>
              <StateContainer>
                <Router>
                  <Switch>
                    <Route exact path="/" component={LoginView} />
                    <Route path="/register" component={RegisterView} />
                    <Route path="/languages" component={LanguagesView} />
                    <Route path="/profile" component={ProfileView} />
                    <Route path="/password-change" component={ChangePasswordView} />
                    <Route path="/home" component={VMView} />
                    <Route path="/products" component={ProductListView} />
                    <Route path="/payment-method-selection" component={PaymentMethodView} />
                    <Route path="/home-no-vm" component={NoVMView} />
                    <Route path="/history-no-vm" component={PurchasesHistoryView} />
                    <Route path="/history-vm" component={(props) => <PurchasesHistoryView vm {...props} />} />
                    <Route path="/product-selection" component={ProductSelectionView} />
                    <Route path="/mandate" component={MandateRequestView} />
                    <Route path="/mandate-result" component={MandateResultView} />
                    <Route path="/mbway" component={MBWayMobilePhone} />
                    <Route path="/load" component={LoadVendingMachineInfo} />
                    <Route path="/password-recovery" component={ResetPasswordForm} />
                    <Route path="/activate" component={ActivateAccount} />
                    <Route path="/session/documents" component={SessionDocumentsView} />
                    <Route path="/revalue-session" component={RevalueSession} />
                    <Route path="/revalue-amount-selection" component={RevalueSelection} />
                    <Route path="/readQr" component={ReadQR} />
                  </Switch>
                </Router>
              </StateContainer>
            </SessionProvider>
          </UserProvider>
        </VendingMachineProvider>
      </IconContextProvider>
    </MuiThemeProvider>
  </Root>
);

export default App;
