import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useIconContext } from '../contexts/IconContext';

const Root = styled.div<any>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: "";
      background-repeat: no-repeat;
      z-index: -1;
      opacity: ${(props: any) => props.opacity};
      background-image: url(${(props: any) => props.image});
      background-position: ${(props: any) => props.position};
      background-size: ${(props: any) => props.size};
      filter: ${(props: any) => (props.usePrimary
    ? 'invert(27%) sepia(10%) saturate(1741%) hue-rotate(91deg) brightness(40%) contrast(85%)'
    : 'brightness(0) sepia(100%) hue-rotate(180deg) saturate(500%) '
        + 'brightness(1.2) contrast(0.8)')};
    }
`;

export default ({
  children, equipmentTypeId, style, opacity = 0.1, position = 'left top 50vh', size = '150%', usePrimary = false,
}: any) => {
  // @ts-ignore
  const { getEquipmentType } = useIconContext();

  const image = useMemo(() => {
    const equipmentType = getEquipmentType(equipmentTypeId);
    return equipmentType?.icon;
  }, [equipmentTypeId]);

  return (
    <Root
      key="image-background"
      style={{
        ...style,
        marginBottom: '80px !important',
      }}
      opacity={opacity}
      image={image?.props?.src}
      size={size}
      position={position}
      usePrimary={usePrimary}
    >
      {children}
    </Root>
  );
};
