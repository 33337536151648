import React, { useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import * as qs from 'qs';
import { getVendingMachineInfo } from '../api/api';
import { useVendingMachine } from '../contexts/VendingMachineContext';
import { useUser } from '../contexts/UserContext';

const LoadVendingMachineInfo = ({ location, history }) => {
  const {
    setStickerId, vendingMachineInfo,
    setVendingMachineInfo, stickerId,
  } = useVendingMachine();
  const { loggedIn } = useUser();
  const { s } = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    (async () => {
      let vmInfo;
      if (s) {
        try {
          // Load vending machine information
          vmInfo = await getVendingMachineInfo(s);
        } catch (e) {
          history.push('/');
        }
        setStickerId(s);
        setVendingMachineInfo(vmInfo);
      }
    })();
  }, []);

  useEffect(() => {
    if (vendingMachineInfo !== undefined) {
/*      if (!loggedIn) {
        history.push(`/?s=${stickerId}`);
        return;
      }*/
      history.push(`/home?s=${stickerId}`);
    }
  }, [vendingMachineInfo]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      margin="auto"
    >
      <CircularProgress color="secondary" />
    </Box>
  );
};

export default LoadVendingMachineInfo;
