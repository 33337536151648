import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enGB from "./en-GB.json";
import esES from "./es-ES.json";
import ptPT from "./pt-PT.json";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  enGB,
  esES,
  ptPT,
};

let defaultLanguage = navigator.language.replace('-', '');

if (!Object.keys(resources).includes(defaultLanguage)) {
  defaultLanguage = 'enGB';
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: defaultLanguage,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;