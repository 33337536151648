import React, { createContext, useContext, useState } from 'react';
import { VendingMachineInfo } from '../types/types';

interface IVendingMachineContext {
    vendingMachineInfo: VendingMachineInfo | undefined,
    setVendingMachineInfo:(vendingMachineInfo: VendingMachineInfo) => void,
    stickerId: string | undefined,
    setStickerId: (stickerId: string) => void,
}

const defaultState = {
  vendingMachineInfo: undefined,
  setVendingMachineInfo: () => undefined,
  stickerId: undefined,
  setStickerId: () => undefined,
};

export const VendingMachineContext = createContext<IVendingMachineContext>(defaultState);

export const VendingMachineProvider = ({ children }) => {
  const [vendingMachineInfo, setVendingMachineInfo] = useState<VendingMachineInfo | undefined>(undefined);
  const [stickerId, setStickerId] = useState<string | undefined>(undefined);

  return (
    <VendingMachineContext.Provider
      value={{
        vendingMachineInfo,
        setVendingMachineInfo,
        stickerId,
        setStickerId,
      }}
    >
      {children}
    </VendingMachineContext.Provider>
  );
};

export const useVendingMachine = () => useContext(VendingMachineContext);
