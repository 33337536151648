import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import CurrencyInput from 'react-currency-input-field';
import { useTranslation } from 'react-i18next';
import PhoneNumber from 'awesome-phonenumber';
import { MdChevronRight } from 'react-icons/all';
import * as qs from 'qs';
import BackButton from '../components/BackButton';
import CardButton from '../components/CardButton';
import { ReactComponent as MBWayLogo } from '../images/logo_mbway.svg';
import TopHeader from '../components/TopHeader';
import { useUser } from '../contexts/UserContext';
import { getDetails } from '../api/api';
import FloatingActionButton from '../components/FloatingActionButton';
import { StyledTextField } from '../components/forms/FormStyles';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  width: 100%;
`;

const Container = styled.div`
  margin: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  height: 100%;
  width: 100%;
`;

const OptionsContainer = styled.div`
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  overflow-y: auto;
  position: relative;
  padding-bottom: 20px;
`;

const PaymentMethodButton = styled(CardButton)`
  background-color: #ffffff;
  color: #000000;
  border: 1px solid rgba(77,76,77,255);
  width: calc(50% - 1em);
`;

const StyledMbWayLogo = styled(MBWayLogo)`
  height: 35%;
`;

const StyledContainer = styled.div`
  margin: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const StyledDiv = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
`;

const Spacer = styled.div`
  margin-bottom: 60px;
`;

export default ({ location }) => {
  const history = useHistory();
  const { i18n } = useTranslation();
  const { user } = useUser();

  const { wallet } = history?.location?.state || {};

  const [num, setNum] = useState<string | undefined>('0.00');
  const [paymentMethodChosen, setPaymentMethodChosen] = useState('SPG');
  const [phoneNumber, setPhoneNumber] = useState('');
  const phoneNumberFormatter = PhoneNumber.getAsYouType('PT');
  const telephoneNumber = new PhoneNumber(phoneNumber || '', 'PT');

  const { s } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const currentLanguage = useMemo(
    () => `${i18n.language[0]}${i18n.language[1]}-${i18n.language[2]}${i18n.language[3]}`,
    [i18n.language],
  );

  const parsedNum = useMemo(() => parseFloat(num?.replace(',', '.') || ''), [num]);
  const canProceed = useMemo(() => typeof parsedNum === 'number' && parsedNum > 0, [num]);

  useEffect(() => {
    (async () => {
      const userInfo = await getDetails(user);
      phoneNumberFormatter.reset(userInfo?.mobilePhone);
      setPhoneNumber(phoneNumberFormatter.getPhoneNumber().getNumber('national'));
    })();
  }, []);

  useEffect(() => {
    if (!wallet) {
      history.push('/home-no-vm');
    }
  }, []);

  const drawPaymentMethods = () => (
    <PaymentMethodButton
      key="SPG"
      onClick={() => {
        setPaymentMethodChosen('SPG');
      }}
    >
      <StyledMbWayLogo />
    </PaymentMethodButton>
  );

  return (
    <Root>
      <TopHeader title={i18n.t('token_revalue')} userLogo/>
      <StyledContainer>
        <StyledDiv>
        <span>{i18n.t('please_enter_amount')}</span>
        <div style={{width: '100%', display: 'inline-flex'}}>
          <CurrencyInput
            id="amount"
            className="MuiInputBase-input MuiInput-input"
            style={{
              borderBottom: '1px solid rgba(77,76,77,255)',
              color: '#000000',
            }}
            defaultValue="0"
            decimalsLimit={2}
            value={num}
            allowNegativeValue={false}
            decimalScale={2}
            disableGroupSeparators={false}
            intlConfig={{locale: currentLanguage, currency: wallet.currencyCode}}
            onValueChange={(value) => setNum(value)}
          />
        </div>
        </StyledDiv>
      <div style={{marginTop: '20px', marginBottom: '8px', alignSelf: 'start'}}>{i18n.t('select_payment_method')}</div>
      <OptionsContainer>
        {drawPaymentMethods()}
      </OptionsContainer>
      {paymentMethodChosen === 'SPG' ? (
        <StyledDiv>
          <span>{i18n.t('mobile_phone')}</span>
          <StyledTextField
            fullWidth
            onChange={(e) => {
              phoneNumberFormatter.reset(e.target.value);
              setPhoneNumber(phoneNumberFormatter.getPhoneNumber().getNumber('national') || e.target.value);
            }}
            id="mobilePhone"
            value={phoneNumber}
          />
        </StyledDiv>
      ) : null}
      {telephoneNumber.isMobile() && canProceed && (
        <FloatingActionButton
          position="bottomright"
          onClick={() => history.push('/revalue-session', {
            wallet,
            mobilePhone: telephoneNumber.getNumber().replace('+', ''),
            gatewayName: 'SPG',
            amount: num,
          })}
        >
          <MdChevronRight/>
        </FloatingActionButton>
      )}
      </StyledContainer>
      <Spacer/>
      <BackButton screen={`/home${s ? `?s=${s}` : ''}`}/>
    </Root>
  );
};
