import React from 'react';
import MBWayLogo from '../images/mbway.png';
import Token from '../images/token.png';
import Card from '../images/card.png';
import Coin from '../images/coin.png';

const getImageFromGateway = (gateway: string) => {
  switch (gateway) {
    case 'TOKEN':
      return <img alt="token" width={45} src={Token} />;
    case 'CARD':
      return <img alt="card" width={45} src={Card} />;
    case 'SPG':
    case 'MBWAY':
      return <img alt="mbway" width={45} src={MBWayLogo} />;
    default:
      return <img style={{ filter: 'grayscale(100%)' }} alt="coin" width={45} src={Coin} />;
  }
};

export default getImageFromGateway;
