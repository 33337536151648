import SessionStatusCodes from '../constants/SessionStatusCodes';
import i18n from '../i18n/i18n';

export const formatSessionStatus = (statusCode: SessionStatusCodes = 0): string => {
  switch (statusCode) {
    case SessionStatusCodes.FAILED:
    case SessionStatusCodes.FAILED_VENDING_MACHINE_NOT_CONFIGURED:
    case SessionStatusCodes.FAILED_VENDING_MACHINE_PROTOCOL_TIMEOUT:
    default:
      return i18n.t("error_occured");

    case SessionStatusCodes.FAILED_UNABLE_TO_CONNECT:
      return i18n.t("error_connecting_to_machine");

    case SessionStatusCodes.DENIED:
    case SessionStatusCodes.DENIED_VENDING_MACHINE_HAS_CREDIT:
    case SessionStatusCodes.DENIED_VENDING_MACHINE_HAS_ACTIVE_SESSION:
      return i18n.t("machine_in_use");

    case SessionStatusCodes.DENIED_WALLET_IN_USE:
      return i18n.t("wallet_in_use");

    case SessionStatusCodes.DENIED_INSUFFICIENT_FUNDS:
    case SessionStatusCodes.DENIED_DIFFERENT_CURRENCY:
      return i18n.t("insufficient_balance");

    case SessionStatusCodes.DENIED_UNDER_MIN_GATEWAY_AMOUNT:
      return i18n.t("credit_less_than_minimum");

    case SessionStatusCodes.DENIED_OVER_MAX_GATEWAY_AMOUNT:
      return i18n.t("credit_greater_than_maximum");

    case SessionStatusCodes.DENIED_GATEWAY_IS_INACTIVE:
      return i18n.t("invalid_payment_method");

    case SessionStatusCodes.REQUESTED:
      return i18n.t("connecting");

    case SessionStatusCodes.ESTABLISHED:
      return i18n.t("connected");

    case SessionStatusCodes.TERMINATION_REQUESTED:
      return i18n.t("disconnecting");

    case SessionStatusCodes.TERMINATED:
    case SessionStatusCodes.TERMINATED_SERVER_INITIATED:
      return i18n.t("session_finished");

    case SessionStatusCodes.TERMINATED_VMU_TIMEOUT:
      return i18n.t("session_expired");

    case SessionStatusCodes.TERMINATED_VMU_PROTOCOL_RESET:
    case SessionStatusCodes.TERMINATED_VMU_PROTOCOL_UNABLE_TO_INITIATE:
    case SessionStatusCodes.TERMINATED_VMU_PROTOCOL_STATE_IS_INVALID:
    case SessionStatusCodes.TERMINATED_VMU_DISCONNECTED_FROM_HUB:
    case SessionStatusCodes.TERMINATED_VMU_DISCONNECTED_FROM_SERVER:
    case SessionStatusCodes.TERMINATED_VMU_APPLICATION_ERROR:
      return i18n.t("error_occured");
  }
};
