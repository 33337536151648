import styled from 'styled-components';
import React from 'react';

import BackButton from './BackButton';

const Root = styled.div`
  height: 30px;
  font-size: 0.8rem;
  color: rgba(77,76,77,255);
  user-select: none;
`;

export default ({ showBackButton, screen }) => (
  <Root>
    <div style={{ minWidth: '15%', maxWidth: '15%', display: 'inline-block' }}>
      {
        showBackButton && (
          <BackButton screen={screen} style={{ float: 'left', color: 'rgba(77,76,77,255) !important' }} />
        )
      }
    </div>
    <div style={{
      minWidth: '70%', maxWidth: '70%', display: 'inline-block', height: '25px', textAlign: 'center'
    }}
    />
    <div style={{ minWidth: '15%', maxWidth: '15%', display: 'inline-block' }} />
  </Root>
);
