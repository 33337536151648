import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DownloadIcon from '@mui/icons-material/Download';
import ReactPDF from '@react-pdf/renderer';
import Button from '@material-ui/core/Button';
import Card from '@mui/material/Card';
import EvStationIcon from '@mui/icons-material/EvStation';
import TimerIcon from '@mui/icons-material/Timer';
import EuroIcon from '@mui/icons-material/Euro';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@material-ui/core';
import moment from 'moment';
import {
  AlertColor, Backdrop, Fade, Modal,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import PhoneNumber from 'awesome-phonenumber';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@mui/material/Alert';
import { DocumentInfo, SessionInfo, TaxDetails } from '../types/types';
import server, {
  closeSession, getDocuments, getTaxDetails, saveTaxDetails,
} from '../api/api';
import PdfBuilder from './PdfBuilder';
import i18n from '../i18n/i18n';
import { FormContainer, StyledTextField } from '../components/forms/FormStyles';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

const DocumentsContainer = styled.div`
  align-self: center;
  width: 100%;
  margin-top: 10px;
`;

const Message = styled.h1`
  text-align: center;
  margin-top: 2.5em;
`;

const HeaderName = styled.h1`
  font: 1.6em Poppins;
  color: rgba(77, 76, 77, 255);
  width: 90%;
  text-align: center;
`;

const StyledSpanPin = styled.span`
  font: 1.2em Poppins;
  color: black
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

const StyledButton = styled(({ ...rest }) => (
  <Button
    {...rest}
  />
))`
  && {
    background-color: #f0b239;
    border-width: 0;
    border-radius: 10px;
    padding: 10px 20px;
    margin: 10px;
    width: 300px;
    text-transform: capitalize;
    font: 0.85em Poppins;
    &:active {
      background-color: rgba(250, 194, 3);
    }

    &:focus {
      background-color: rgba(250, 194, 3);
    }
  }
`;

const StyledButton2 = styled(({ ...rest }) => (
  <Button
    {...rest}
  />
))`
  && {
    background-color: #f0b239;
    border-width: 0;
    border-radius: 10px;
    padding: 10px 20px;
    margin: 10px;
    width: 120px;
    text-transform: capitalize;
    font: 0.85em Poppins;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4%;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 25%;
  height: 100px;
  padding: 10px
`;

const StyledSpan = styled.span`
  margin-top: 20px;
  font-weight: bold;
`;

const StyledP = styled.p`
  margin-top: 1px;
`;

const StyledDiv = styled.div`
  min-height: 12vh;
`;

type Error = {
  nif: string | null,
  mobilePhone: string | null,
  email: string | null,
};

const SessionDocumentsView = ({ location }) => {
  const [sessionInfo, setSessionInfo] = useState<SessionInfo | null>(null);
  const [loadingTerminate, setLoadingTerminate] = useState(false);
  const [showTaxpayerModal, setShowTaxpayerModal] = useState(false);
  const [error, setError] = React.useState<Error>({
    nif: null,
    mobilePhone: null,
    email: null,
  });
  const [phoneNumber, setPhoneNumber] = useState('');
  const [vatNumber, setVatNumber] = useState('');
  const [email, setEmail] = useState('');
  const [taxpayerSaveLoading, setTaxpayerSaveLoading] = useState(false);
  const [taxpayerLoading, setTaxpayerLoading] = useState(true);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [notificationClass, setNotificationClass] = useState('success');
  const phoneNumberFormatter = PhoneNumber.getAsYouType('PT');
  const telephoneNumber = new PhoneNumber(phoneNumber || '', 'PT');
  const [backupTaxDetails, setBackupTaxDetails] = useState<TaxDetails | null>({
    taxId: '',
    email: '',
    mobilePhone: '',
  });

  const sessionId = location.pathname.split('/')[3];
  const isEVRelated = sessionInfo?.vendingMachine?.equipmentType?.label === 'EV Charger'
    || sessionInfo?.vendingMachine?.equipmentType?.label === 'EV Station';

  const showError = () => {
    setNotificationClass('error');
    setNotificationVisible(true);
  };

  const fetchDocuments = async (id: string) => {
    try {
      const response = await getDocuments(id);
      setSessionInfo(response);
    } catch (err: any) {
      showError();
    }
  };

  const fetchTaxDetails = async (id: string) => {
    try {
      const response = await getTaxDetails(id);

      if (!response) {
        setTaxpayerLoading(false);
        setBackupTaxDetails(null);
        return;
      }

      setBackupTaxDetails(response);

      if (response.taxId) {
        setVatNumber(response.taxId);
      }

      if (response.mobilePhone) {
        phoneNumberFormatter.reset(response.mobilePhone?.replace('351', ''));
        setPhoneNumber(phoneNumberFormatter.getPhoneNumber().getNumber('national') || '');
      }

      if (response.email) {
        setEmail(response.email);
      }

      setTaxpayerLoading(false);
    } catch (err: any) {
      showError();
    }
  };

  const handleSessionUpdate = (newInfo) => {
    setSessionInfo((prevState: any) => ({
      ...prevState,
      state: newInfo.sessionState,
      extra: {
        ...prevState.extra,
        ...(newInfo.update && { chargingData: newInfo.update }),
      },
    }));

    if (newInfo.state === 300) {
      setLoadingTerminate(false);
    }
  };

  const closeTaxpayerModal = () => {
    setShowTaxpayerModal(false);
    setError({
      nif: null,
      mobilePhone: null,
      email: null,
    });
    setPhoneNumber('');
    setVatNumber('');
    setEmail('');
  };

  const openTaxpayerModal = async () => {
    setTaxpayerLoading(true);
    setShowTaxpayerModal(true);
    await fetchTaxDetails(sessionId);
  };

  const onCloseAlert = () => setNotificationVisible(false);

  const getChangedFields = (newFields): TaxDetails => {
    const mutableFields = { ...newFields };

    // replace newFileds empty strings with null
    Object.keys(mutableFields).forEach((key) => {
      if (mutableFields[key] === '') {
        mutableFields[key] = null;
      }
    });

    // check which fields have changed
    const changedFields = {};
    Object.keys(mutableFields).forEach((key) => {
      if (!backupTaxDetails || backupTaxDetails[key] !== mutableFields[key]) {
        changedFields[key] = mutableFields[key];
      }
    });
    return changedFields as TaxDetails;
  };

  const saveTaxpayerDetails = async () => {
    if (!error.nif && !error.mobilePhone && !error.email) {
      setTaxpayerSaveLoading(true);
      try {
        const taxDetails = getChangedFields({
          taxId: vatNumber,
          email,
          mobilePhone: telephoneNumber?.getNumber()?.replace('+', '') || '',
        });

        if (Object.keys(taxDetails).length === 0) {
          setTaxpayerSaveLoading(false);
          setNotificationClass('success');
          setNotificationVisible(true);
          closeTaxpayerModal();
          return;
        }

        await saveTaxDetails(sessionId, taxDetails);
        setTaxpayerSaveLoading(false);
        setNotificationClass('success');
        setNotificationVisible(true);
      } catch (err: any) {
        setTaxpayerSaveLoading(false);
        showError();
      }

      closeTaxpayerModal();
    }
  };

  useEffect(() => {
    fetchDocuments(sessionId);
  }, []);

  useEffect(() => {
    if (isEVRelated) {
      server.onSocket('session_update', handleSessionUpdate);
    }

    return () => {
      server.offSocket('session_update', handleSessionUpdate);
    };
  }, [sessionInfo]);

  const chargingData = sessionInfo?.extra?.chargingData;
  const price = (chargingData?.kWh * (chargingData?.priceKwh / 100)).toString();
  const validPrice = !(price === 'NaN');
  const time = moment.duration(chargingData?.chargingTime, 'seconds');

  if (!sessionInfo) {
    return (
      <Root>
        <CircularProgress
          size={200}
          sx={{
            marginTop: '10px',
            marginBottom: '30px',
            color: '#f0b239',
            animationDuration: '80000ms',
          }}
          thickness={1}
          variant="indeterminate"
        />
      </Root>
    );
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    minHeight: '50vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    bgcolor: 'background.paper',
    border: '2px solid #f0b239',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };

  const canEditTaxDetails = sessionInfo?.state === 100
    && !sessionInfo?.documents?.some((document) => document.type === 'INVOICE');

  return (
    <Root>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
        open={notificationVisible}
        onClose={onCloseAlert}
      >
        <Alert
          onClose={onCloseAlert}
          severity={notificationClass as AlertColor}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {i18n.t(notificationClass)}
        </Alert>
      </Snackbar>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showTaxpayerModal}
        onClose={closeTaxpayerModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={showTaxpayerModal}>
          <Box sx={style}>
            {
              taxpayerLoading ? (
                <CircularProgress
                  size={50}
                  style={{ color: '#f0b239' }}
                  variant="indeterminate"
                />
              ) : (
                <>
                  <Typography id="transition-modal-title" variant="h6" component="h2">
                    {i18n.t('insert_taxpayer_details')}
                  </Typography>
                  <FormContainer>
                    <form noValidate autoComplete="off" id="taxpayer">
                      <StyledDiv style={{ paddingTop: '20px' }}>
                        <span>{i18n.t('vat_number')}</span>
                        <StyledTextField
                          fullWidth
                          id="nif"
                          placeholder="PT255555555"
                          onChange={(e) => {
                            const valid = /^PT\d{9}$/.test(e.target.value) || e.target.value === '';
                            setVatNumber(e.target.value);
                            setError((prevState) => ({
                              ...prevState,
                              nif: valid ? null : i18n.t('invalid_vat_number'),
                            }));
                          }}
                          defaultValue={vatNumber}
                          error={!!error?.nif}
                          helperText={error.nif ? error.nif : ''}
                        />
                      </StyledDiv>
                      <StyledDiv>
                        <span>{i18n.t('mobile_phone_number')}</span>
                        <StyledTextField
                          fullWidth
                          onChange={(e) => {
                            phoneNumberFormatter.reset(e.target.value);
                            const formattedPhoneNumber = phoneNumberFormatter.getPhoneNumber().getNumber('national');
                            const tempVerification = new PhoneNumber(formattedPhoneNumber || '', 'PT');
                            const valid = tempVerification.isMobile()
                              || (!formattedPhoneNumber && e.target.value === '');
                            setPhoneNumber(formattedPhoneNumber || e.target.value);
                            setError((prevState) => ({
                              ...prevState,
                              mobilePhone: valid
                                ? null : i18n.t('invalid_phone_number'),
                            }));
                          }}
                          id="mobilePhone"
                          value={phoneNumber}
                          placeholder="999 999 999"
                          error={!!error?.mobilePhone}
                          helperText={error.mobilePhone ? error.mobilePhone : ''}
                        />
                      </StyledDiv>
                      <StyledDiv style={{ paddingTop: '20px' }}>
                        <span>Email</span>
                        <StyledTextField
                          fullWidth
                          id="email"
                          onChange={(e) => {
                            const valid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(e.target.value)
                              || e.target.value === '';
                            setEmail(e.target.value);
                            setError((prevState) => ({
                              ...prevState,
                              email: valid ? null : i18n.t('invalid_email'),
                            }));
                          }}
                          placeholder="email@email.com"
                          error={!!error?.email}
                          defaultValue={email}
                          helperText={error.email ? error.email : ''}
                        />
                      </StyledDiv>
                      <ButtonsContainer>
                        <StyledButton
                          variant="outlined"
                          color="secondary"
                          disabled={error.nif || error.mobilePhone || error.email || taxpayerSaveLoading}
                          onClick={saveTaxpayerDetails}
                        >
                          {taxpayerSaveLoading ? (
                            <CircularProgress
                              size={20}
                              style={{ color: 'white' }}
                              variant="indeterminate"
                            />
                          ) : i18n.t('save')}
                        </StyledButton>
                      </ButtonsContainer>
                    </form>
                  </FormContainer>
                </>
              )
            }
          </Box>
        </Fade>
      </Modal>
      {sessionInfo && (
        <HeaderName>
          {sessionInfo?.vendingMachine?.identificationNumber}
          {' '}
          |
          {' '}
          {sessionInfo?.vendingMachine?.name}
        </HeaderName>
      )}
      {!isEVRelated && <Message>{i18n.t('thanks_for_purchase')}</Message>}
      {isEVRelated && (
        <>
          <StyledSpanPin>
            PIN:
            {sessionInfo?.pin}
          </StyledSpanPin>
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
              size={200}
              sx={{
                marginTop: '10px',
                marginBottom: '30px',
                color: '#f0b239',
                animationDuration: '80000ms',
              }}
              thickness={1}
              variant={[100, 0].includes(sessionInfo?.state) ? 'indeterminate' : 'determinate'}
              value={[100, 0].includes(sessionInfo?.state) ? undefined : 100}
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span
                style={{ color: 'black', textAlign: 'center' }}
              >
                {[100, 0].includes(sessionInfo?.state) ? i18n.t('charging') : i18n.t('charging_complete')}
              </span>
              {[100, 0].includes(sessionInfo?.state)
                && (
                  <ButtonsContainer>
                    <StyledButton2
                      variant="outlined"
                      color="secondary"
                      disabled={loadingTerminate}
                      onClick={async () => {
                        setLoadingTerminate(true);
                        await closeSession(sessionInfo?.sessionId);
                      }}
                    >
                      {loadingTerminate
                        && (
                          <CircularProgress
                            style={{ marginRight: '10px' }}
                            size={20}
                            color="secondary"
                            variant="indeterminate"
                          />
                        )}
                      Terminar
                    </StyledButton2>
                  </ButtonsContainer>
                )}
            </Box>
          </Box>
          {
            chargingData && (
              <div style={{ width: 'calc(100% - 24px)', marginBottom: '30px' }}>
                <Row>
                  {
                    chargingData.kWh !== undefined && (
                      <StyledCard style={{ backgroundColor: '#DCDCDC' }}>
                        <EvStationIcon fontSize="large" sx={{ color: '#808080' }} />
                        <StyledSpan>kWh:</StyledSpan>
                        <StyledP>
                          {chargingData?.kWh !== null
                            ? chargingData?.kWh : '---'}
                        </StyledP>
                      </StyledCard>
                    )
                  }
                  {
                    chargingData.chargingTime !== undefined && (
                      <StyledCard
                        style={{
                          backgroundColor: '#DCDCDC',
                        }}
                      >
                        <TimerIcon fontSize="large" sx={{ color: '#808080' }} />
                        <StyledSpan>{i18n.t('duration')}</StyledSpan>
                        <StyledP>
                          {chargingData?.chargingTime !== null
                            ? moment.utc(time.asMilliseconds()).format('HH:mm:ss') : '---'}
                        </StyledP>
                      </StyledCard>
                    )
                  }
                  {
                    (chargingData.priceKwh !== undefined && chargingData.kWh !== undefined) && (
                      <StyledCard style={{ backgroundColor: '#DCDCDC' }}>
                        <EuroIcon fontSize="large" sx={{ color: '#808080' }} />
                        <StyledSpan>{i18n.t('price')}</StyledSpan>
                        <StyledP>
                          {validPrice ? `${parseFloat(price).toFixed(2)}€` : '---'}
                        </StyledP>
                      </StyledCard>
                    )
                  }
                </Row>
              </div>
            )
          }
          <DocumentsContainer>
            {canEditTaxDetails && (
              <ButtonsContainer>
                <StyledButton
                  variant="outlined"
                  color="secondary"
                  onClick={openTaxpayerModal}
                >
                  {i18n.t('insert_taxpayer_details')}
                </StyledButton>
              </ButtonsContainer>
            )}
          </DocumentsContainer>
        </>
      )}
      <DocumentsContainer>
        {sessionInfo && sessionInfo.documents.map((documentInfo: DocumentInfo) => (
          <ButtonsContainer>
            <StyledButton
              variant="outlined"
              color="secondary"
              onClick={async () => {
                let url;
                if (documentInfo?.url) {
                  url = documentInfo?.url;
                } else if (documentInfo?.text?.includes(' ')) {
                  try {
                    const pdfDocument = await ReactPDF.pdf(<PdfBuilder document={documentInfo} />).toBlob();
                    url = window.URL.createObjectURL(pdfDocument);
                  } catch (err: any) {
                    // ignore
                  }
                } else {
                  url = `data:application/pdf;base64,${documentInfo.text}`;
                }

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', i18n.t('document'));
                link.click();
                link.remove();
              }}
            >
              <DownloadIcon style={{ color: 'white', margin: '0 10px 0 0' }} />
              {documentInfo.type === 'CUSTOMER' ? i18n.t('payment_document') : i18n.t('invoice')}
            </StyledButton>
          </ButtonsContainer>
        ))}
      </DocumentsContainer>
    </Root>
  );
};

export default SessionDocumentsView;
