import React from 'react';
import styled from 'styled-components';
import * as qs from 'qs';
import ProfileForm from '../components/forms/ProfileForm';
import TopHeader from '../components/TopHeader';
import BackButton from '../components/BackButton';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: 100%;
`;

const ProfileView = ({ location }) => {
  const { s } = qs.parse(location.search, { ignoreQueryPrefix: true });

  return (
    <Root>
      <div style={{ flex: 1 }}>
        <TopHeader title="change_profile" userLogo />
        <ProfileForm />
        <BackButton screen={`/home${s ? `?s=${s}` : ''}`} />
      </div>
    </Root>
  );
};

export default ProfileView;
