import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { Typography } from '@material-ui/core';
import * as qs from 'qs';
import { formatValue } from 'react-currency-input-field';
import AccordionItem from '../components/accordion-item';
import Accordion from '../components/accordion';
import { useVendingMachine } from '../contexts/VendingMachineContext';
import { Product } from '../types/types';
import BackgroundImage from '../components/BackgroundImage';
import i18n from '../i18n/i18n';

const ListButtonsContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const ListProducts = styled.div<{height: number}>`
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 99%;
  overflow-y: auto;
  position: relative;
  padding-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.05);
  height: 100%;
`;

const Label = styled.span`
  font-size: 0.85em;
  min-width: 110px;
  display: inline-block;
  font-family: Poppins, sans-serif;
  font-weight: bold;
`;

export default ({ location, currentLanguage = 'en-GB' }) => {
  const topRef = useRef<HTMLDivElement>(null);
  const { s } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [scrollHeight] = useMemo(
    () => [(window.innerHeight - (topRef?.current?.clientHeight || 0) - 300)
      , (window.innerWidth - (topRef?.current?.clientWidth || 0) - 200)], [],
  );
  const [vmProducts, setVmProducts] = useState<any[]>([]);
  const { vendingMachineInfo } = useVendingMachine();
  const history = useHistory();

  useEffect(() => {
    if (!vendingMachineInfo && !s) {
      history.push('/home-no-vm');
    } else if (!vendingMachineInfo && s) {
      history.push(`/load?s=${s}`);
    }

    if (vendingMachineInfo && vendingMachineInfo.products) {
      setVmProducts(vendingMachineInfo.products);
    }
  }, []);

  const content = () => (
    <>
      <ListProducts height={scrollHeight}>
        <Accordion
          key={Math.random()}
        >
          {vmProducts.map((product: Product) => (
            <AccordionItem
              headerPaddingY={5}
              header={(
                <>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '10%',
                    justifyContent: 'start',
                    alignItems: 'start',
                    height: '100%',
                    fontSize: '0.85em',
                  }}
                  >
                    <Typography style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        display: 'block',
                        fontFamily: 'Poppins, sans-serif',
                        fontSize: '1em',
                      }}
                      >
                        <span>{product.itemNumber}</span>
                      </Typography>
                  </div>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '60%',
                    justifyContent: 'start',
                    alignItems: 'start',
                    height: '100%',
                    fontSize: '0.85em',
                  }}
                  >
                    <Typography style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        display: 'block',
                        fontFamily: 'Poppins, sans-serif',
                        fontSize: '1em',
                      }}
                      >
                        <span>{product.productName}</span>
                      </Typography>
                  </div>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '30%',
                    justifyContent: 'right',
                    alignItems: 'center',
                    height: '100%',
                    fontSize: '0.85em',
                  }}
                  >
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '80%',
                        justifyContent: 'left',
                        alignItems: 'center',
                        height: '100%',
                      }}
                      >
                        <Typography
                          style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            display: 'block',
                            fontFamily: 'Poppins, sans-serif',
                            fontSize: '1em',
                          }}
                        >
                          <span>
                            {formatValue({
                              value: (product.price / 100).toFixed(2),
                              groupSeparator: ',',
                              decimalSeparator: '.',
                              decimalScale: 2,
                              intlConfig: { locale: currentLanguage, currency: 'EUR' },
                            })}
                          </span>
                        </Typography>
                      </div>
                  </div>
                </>
                )}
              notExpandable
            >
              <></>
            </AccordionItem>
          ))}
        </Accordion>
      </ListProducts>
    </>
  );

  return (
    <BackgroundImage equipmentTypeId={vendingMachineInfo?.equipmentType.id}>
      <ListButtonsContainer>
        {content()}
      </ListButtonsContainer>
      <div style={{ flex: 1 }} />
    </BackgroundImage>
  );
};
